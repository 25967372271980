import { integerCentToFloatString } from "vincent-utils";
import { formaters } from "vincent-utils";
import _ from "underts";
import { BanqueLine2 } from "compta-shared";

interface SimilarLinesTableProps {
  similarLines: BanqueLine2[];
  onToggleSimilarLine: (_id: string) => void;
  selectedSimilarLines: string[];
  onToggleAllSimilarLines: () => void;
}

const SimilarLinesTable = (props: SimilarLinesTableProps) => {
  const {
    similarLines,
    onToggleSimilarLine,
    selectedSimilarLines,
    onToggleAllSimilarLines,
  } = props;
  const similarComps =
    similarLines &&
    _.sortBy(similarLines, "date").map((line, idx) => (
      <tr key={"similarLines" + idx}>
        <th>
          <input
            type="checkbox"
            onChange={() => onToggleSimilarLine(line._id)}
            checked={
              selectedSimilarLines && selectedSimilarLines.includes(line._id)
            }
          />
        </th>
        <th>{formaters.formatDateAsDDN(line.date)}</th>
        <th>{line.libelle}</th>
        <th>{integerCentToFloatString(line.montantCent)}</th>
      </tr>
    ));
  return (
    <div className="card w-75">
      <div className="card-header">
        Sélectionnez parmi ces mouvements ressemblants ceux que vous voulez
        assigner de la même façon.
      </div>
      <div className="card-body">
        <table className="table">
          <thead>
            <tr>
              <th>
                <input
                  type="checkbox"
                  onChange={onToggleAllSimilarLines}
                  checked={selectedSimilarLines.length === similarLines.length}
                />
              </th>
              <th>Date</th>
              <th>Libellé</th>
              <th>Montant</th>
            </tr>
          </thead>
          <tbody>{similarComps}</tbody>
        </table>
      </div>
    </div>
  );
};

export default SimilarLinesTable;
