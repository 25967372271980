import { Form } from "react-bootstrap";
import {useEffect, useState} from "react";

type IntegerInputProps = IntegerDisabledInputProps | IntegerEnabledInputProps;

interface IntegerDisabledInputProps {
  value: number | null;
  id?: string;
  disabled: true;
}

interface IntegerEnabledInputProps {
  onValueChange: (value: number | null) => void;
  value: number | null;
  id?: string;
  forbidZero?: boolean;
}

const IntegerInput = (props: IntegerInputProps) => {
  const [integerString, setIntegerString] = useState(
    (typeof props.value === "number" && props.value.toString()) || ""
  );
  useEffect(() => {
    setIntegerString(
      (typeof props.value === "number" && props.value.toString()) || ""
    );
  }, [props.value]);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if ("disabled" in props) {
      return;
    }
    const value = event.target.value;
    if (
      value === "" ||
      (props.forbidZero && value === "0") ||
      Number.isNaN(parseInt(value))
    ) {
      setIntegerString("");
      return;
    }

    const parsedValue = parseInt(value);
    setIntegerString(parsedValue.toString());
  };
  const handleBlur = () => {
    if ("disabled" in props) {
      return;
    }
    if (integerString === "") {
      props.onValueChange(null);
    }
    const value = parseInt(integerString);
    if (Number.isNaN(value)) {
      return;
    }
    props.onValueChange(value);
    // setIntegerString(value.toString()); //on remet la valeur sanitized
  };
  const isValidValue = Number.isInteger(parseInt(integerString));
  const isTouched = integerString !== "";
  return (
    <Form.Control
      type="text"
      id={props.id}
      className={`form-control ${
        isTouched && !isValidValue ? "is-invalid" : isTouched ? "is-valid" : ""
      }`}
      value={integerString}
      onChange={handleChange}
      onBlur={handleBlur}
      disabled={"disabled" in props && props.disabled}
    />
  );
};

export default IntegerInput;
