import { FactureState } from "../hooks/useFacture";
import { NewFacture } from "compta-shared";
const factureStateToFacture = (factureState: FactureState) => {
  if (!factureState.mode) {
    throw new Error("factureState.mode is null in factureStateToFacture");
  }
  return {
    modeRef: factureState.modeRef,
    mode: factureState.mode,
    ...(factureState.date ? { date: factureState.date } : null),
    libellé: factureState.libellé,
    montantCent: factureState.montantCent,
    ...(factureState.compte ? { compte: factureState.compte } : null),
    ...(factureState.règlements.length > 0
      ? { règlements: règlementsStateToRèglements(factureState.règlements) }
      : null),
    ...(factureState.ventilations.length > 0
      ? {
          ventilations: ventilationsStateToVentilations(
            factureState.ventilations,
          ),
        }
      : null),
  } as NewFacture;
};

const règlementsStateToRèglements = (
  règlements: FactureState["règlements"],
) => {
  return règlements
    .filter((r) => !!r.date && !!r.montantCent)
    .map((r) => {
      return {
        date: r.date!,
        montantCent: r.montantCent,
        ...(r.ventilations.length > 0
          ? { ventilations: ventilationsStateToVentilations(r.ventilations) }
          : null),
      };
    });
};

const ventilationsStateToVentilations = (
  ventilations: FactureState["ventilations"],
) => {
  return ventilations.map((v) => {
    return {
      compte: v.compte,
      montantCent: v.montantCent,
      ...(v.pourcentageCent ? { pourcentageCent: v.pourcentageCent } : null),
    };
  });
};

export default factureStateToFacture;
