import LoginScreen from "./LoginScreen";
import { utils } from "vincent-utils";
import { useEffect, useState } from "react";
import { useAtom } from "jotai";
import { LoggedUserAtom } from "../state/globalState.ts";
import ProviderContainer from "./ProviderContainer.tsx";

const devHost = import.meta.env.VITE_DEV_HOST || "";
const prefix = "comptaRest";
const cleanedUrl = "getConnectedUser";
const getUser = async () => {
  const response = await fetch(
    `${devHost ? devHost + "/" : ""}${prefix}/${cleanedUrl}`,
    {
      mode: "cors",
      credentials: "include",
    }
  );
  if (response.ok) {
    const text = await response.text();
    return JSON.parse(text, utils.jsonDateReviver);
  } else {
    throw new Error(`invalide json dans fetch ${prefix}/${cleanedUrl}`);
  }
};

const LoginSwitcher = () => {
  const [user, setUser] = useAtom(LoggedUserAtom);
  const [shouldFetch, setShouldFetch] = useState(true);
  useEffect(() => {
    if (!shouldFetch) {
      return;
    }
    setShouldFetch(false);
    getUser().then(setUser);
  }, [shouldFetch]);
  const loginCallBack = () => {
    setShouldFetch(true);
  };
  if (user) {
    return <ProviderContainer user={user} />;
  } else {
    return <LoginScreen callBack={loginCallBack} />;
  }
};

export default LoginSwitcher;
