import SimilarLinesTable from "../components/SimilarLinesTable";
import { getSimilarLines } from "../processing/similarLines";
import { BanqueLine2 } from "compta-shared";
import { trpc } from "../../main/components/ProviderContainer.tsx";

interface SimilarLinesContainerProps {
  lineToCompare: BanqueLine2;
  similarLinesIds: string[];
  onSetSimilarLinesIds: (ids: string[]) => void;
}

const SimilarLinesContainer = (props: SimilarLinesContainerProps) => {
  const { data: unRapprochedBanqueLines } =
    trpc.banque.getNotRapprochedBanqueLines.useQuery();
  const similarLines = getSimilarLines(
    props.lineToCompare,
    unRapprochedBanqueLines || [],
  );
  console.log("similarLines", similarLines);
  // const [selectedSimilarLines, setSelectedSimilarLines] = useAtom(
  //   selectedSimilarLinesIdsAtom,
  // );
  const toggleSimilarLine = (id: string) => {
    if (props.similarLinesIds.includes(id)) {
      props.onSetSimilarLinesIds(props.similarLinesIds.filter((i) => i !== id));
    } else {
      props.onSetSimilarLinesIds([...props.similarLinesIds, id]);
    }
  };
  const toggleAllSimilarLines = () => {
    if (props.similarLinesIds.length === similarLines.length) {
      props.onSetSimilarLinesIds([]);
      return;
    }
    props.onSetSimilarLinesIds(similarLines.map((l) => l._id));
  };
  return (
    <div>
      {similarLines && similarLines.length > 0 ? (
        <SimilarLinesTable
          similarLines={similarLines}
          onToggleSimilarLine={toggleSimilarLine}
          selectedSimilarLines={props.similarLinesIds}
          onToggleAllSimilarLines={toggleAllSimilarLines}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default SimilarLinesContainer;
