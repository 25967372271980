interface AutoCompleteCandidatesProps<Candidate extends string> {
  onSelectCandidate: (candidate: number) => void;
  candidates: Candidate[];
  currentCandidate: string;
}
const AutoCompleteCandidates = <Candidate extends string>(props: AutoCompleteCandidatesProps<Candidate>) => {
  const { onSelectCandidate } = props;
  const candidates = props.candidates.map(function (candidate, index) {
    if (props.currentCandidate === candidate) {
      return (
        <li
          className="autoCompleteCandidates autoCompleteCandidateSelected"
          key={candidate + "_autocomplete_candidates"}
        >
          {candidate}
        </li>
      );
    } else {
      return (
        <li
          className="autoCompleteCandidates"
          key={candidate + "_autocomplete_candidates"}
          onMouseDown={() => onSelectCandidate(index)}
        >
          {candidate}
        </li>
      );
    }
  });
  return <ul>{candidates}</ul>;
};
export default AutoCompleteCandidates;
