import AutoCompleteCandidates from "./AutoCompleteCandidates";
import * as React from "react";
import TagsInput from "./TagsInput";
import "./autoComplete.css";
import { useEffect } from "react";

function getCaretPosition(editableDiv: EventTarget) {
  let caretPos = 0;
  const sel = document.getSelection();
  if (!sel) {
    throw new Error("no selection");
  }
  if (sel.rangeCount) {
    const range = sel.getRangeAt(0);
    if (range.commonAncestorContainer.parentNode == editableDiv) {
      caretPos = range.endOffset;
    }
  }
  return caretPos;
}

type AutoCompleteProps =
  | AutoCompleteWithoutTagsProps
  | AutoCompleteWithTagsProps;

interface AutoCompleteWithoutTagsProps {
  candidates: string[];
  value: string;
  onSetSearch: (value: string | null) => void;
  onSetValue: (value: string) => void;

  id?: string;
  className?: string;
  style?: React.CSSProperties;
  width?: string;
  tagsOn: false;
  acceptNewValues: boolean;
  isValid?: boolean;
  invalidMessage?: string;
  isTouched?: boolean;
}

interface AutoCompleteWithTagsProps {
  candidates: string[];
  value: string;
  onSetSearch: (value: string | null) => void;
  onSetValue: (value: string) => void;

  id?: string;
  className?: string;
  style?: React.CSSProperties;
  width?: string;
  tagsOn: true;
  tags: string[];
  onTagsChange: (tags: string[]) => void;
  acceptNewTags: boolean;
  isValid?: boolean;
  invalidMessage?: string;
}

const AutoComplete = (props: AutoCompleteProps) => {
  const [double, setDouble] = React.useState(false);
  const [selectedCandidateIdx, setSelectedCandidateIdx] = React.useState(0);
  const [tempString, setTempString] = React.useState(props.value);
  const [dirty, setDirty] = React.useState(false);
  const [isTouched, setIsTouched] = React.useState(false);
  const [tags, setTags] = React.useState<string[]>([]);
  useEffect(() => {
    setTempString(props.value);
    setDirty(false);
  }, [props.value]);
  useEffect(()=>{
    //quand le isTouched est passé à true par les props (remise à zéro de la facture), on remet à zéro le isTouched local
    if ("isTouched" in props && !props.isTouched) setIsTouched(false);
  }, ["isTouched" in props && props.isTouched])
  const removeTag = (tag: string) => {
    setTags(tags.filter(t => t !== tag));
  };
  const addTag = (tag: string) => {
    const set = new Set([...tags, tag]);
    setTags(Array.from(set));
  };
  const isShowingCandidates = props.candidates.length > 0;
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsTouched(true);
    props.onSetSearch(event.target.value);
    setTempString(event.target.value);
    setDirty(true);
  };
  const handleKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "ArrowDown") {
      setSelectedCandidateIdx(selectedCandidateIdx => {
        return selectedCandidateIdx + 1 < props.candidates.length
          ? selectedCandidateIdx + 1
          : selectedCandidateIdx;
      });
    } else if (event.key === "ArrowUp") {
      setSelectedCandidateIdx(selectedCandidateIdx => {
        return selectedCandidateIdx > 0
          ? selectedCandidateIdx - 1
          : selectedCandidateIdx;
      });
    } else if (
      (isShowingCandidates || (!props.tagsOn && props.acceptNewValues)) &&
      (event.key === "ArrowRight" ||
        event.key === "Tab" ||
        event.key === "Enter")
    ) {
      if (isShowingCandidates) {
        acceptChoice();
      }
    }
    //quand on appuie sur la virgule, ça ferme les suggestions et ça crée un nouveau tag sans prendre en compte les suggestions
    if (event.key === ",") {
      if (props.tagsOn && props.acceptNewTags) {
        setTags(tags => [...tags, tempString.split(",")[0]]);
        setTempString("");
      }
      props.onSetSearch(null);
    }
    let caretPos = getCaretPosition(event.target);
    if (event.key === "Backspace" && caretPos === 0) {
      if (double) {
        if ("tagsOn" in props) {
          removeTag(tags[tags.length - 1]);
        }
      } else {
        setDouble(true);
      }
    } else {
      setDouble(false);
    }
  };
  const acceptChoice = (index?: number) => {
    if (index != null) {
      //si il y a un index, c'est qu'on a cliqué sur le candidat
      if (props.tagsOn) {
        addTag(props.candidates[index]);
        setTempString("");
      } else {
        setTempString(props.candidates[index]);
        props.onSetValue(props.candidates[index]);
        setDirty(false);
      }
    } else {
      //sinon ça veut dire qu'on est passé par le clavier et donc c'est le candidat déjà sélectionné
      if (props.tagsOn) {
        addTag(props.candidates[selectedCandidateIdx]);
        setTempString("");
      } else {
        setTempString("");
        props.onSetValue(props.candidates[selectedCandidateIdx]);
        setDirty(false);
      }
    }

    props.onSetSearch(null);
    resetIndex();
  };
  const handleBlur = () => {
    setIsTouched(true);
    if (isShowingCandidates) {
      acceptChoice();
      return;
    }
    if (props.tagsOn) {
      props.onTagsChange(tags);
    } else {
      if (tempString === "") {
        props.onSetValue(tempString); //si on veut effacer un champ, il est important de forcer onSetValue sur le blur
      }
    }

    if (!props.tagsOn && !props.acceptNewValues && dirty) {
      setTempString("");
      props.onSetValue("");
    }

    if (!props.tagsOn && props.acceptNewValues && dirty) {
      props.onSetValue(tempString);
      setDirty(false);
    }
  };
  const resetIndex = () => {
    setSelectedCandidateIdx(0);
  };

  const candidatesCompo = (
    <div className="autoCompletePill">
      <AutoCompleteCandidates
        candidates={props.candidates}
        onSelectCandidate={acceptChoice}
        currentCandidate={props.candidates[selectedCandidateIdx]}
      />
    </div>
  );
  const simpleImputCompo = (
    <input
      type="text"
      id={props.id}
      className={
        "form-control" +
        " " +
        ("isValid" in props
          ? !isTouched
            ? ""
            : !!props.isValid
            ? "is-valid"
            : "is-invalid"
          : "")
      }
      value={tempString}
      style={{ width: props.width }}
      onKeyUp={handleKeyUp}
      onBlur={handleBlur}
      onChange={handleChange}
      autoComplete="off"
    />
  );
  const inputToShow = props.tagsOn ? (
    <TagsInput
      value={tempString}
      onSearch={search => {
        props.onSetSearch(search);
        setTempString(search);
      }}
      onKeyUp={handleKeyUp}
      tags={tags}
      onBlur={handleBlur}
      onRemoveTag={removeTag}
    />
  ) : (
    simpleImputCompo
  );
  const compoToShow = isShowingCandidates ? candidatesCompo : null;
  return (
    <div className={props.className}>
      {inputToShow}
      <div className="clearFloat">{compoToShow}</div>
      {isTouched && "isValid" in props && !props.isValid && (
        <div className="invalid-feedback d-block">{props.invalidMessage}</div>
      )}
    </div>
  );
};
export default AutoComplete;
