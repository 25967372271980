export default [
  "Espèces",
  "Carte1",
  "CartePerso",
  "Virement1",
  "Chèque1",
  "Prélèvement1"
] as const;

//TODO le rendre paramétrable par les utilisateurs
