import YearChooser from "../factures/components/YearChooser";
import _ from "underts";
import { Route, Switch, useLocation } from "wouter";
import GrandLivreComp from "./GrandLivreComp.tsx";
import { useEffect } from "react";

const GrandLivreMainPanel = () => {
  const currentYear = new Date().getFullYear();
  const yearProposed = _.range(5, -1, -1).map((i) => currentYear - i);
  const [location, setLocation] = useLocation();
  const yearSelected = _.parseInt(location.split("/")[1]);
  console.log("yearSelected in GrandLivreMainPanel", yearSelected);
  const handleSelectYear = (year: number) => {
    console.log("handleSelectYear", year);
    setLocation("/" + year);
  };
  useEffect(() => {
    if (!yearSelected) {
      console.log("setYearEffect");
      setLocation("/" + currentYear, { replace: true });
    }
  }, []);

  return (
    <div>
      <div>
        <YearChooser
          yearSelected={yearSelected}
          yearChoices={yearProposed}
          onYearSelected={handleSelectYear}
        />
      </div>
      <Switch>
        <Route path={"/:year"} nest>
          <GrandLivreComp yearSelected={yearSelected as number} />
        </Route>
        {/*<Redirect to={"/" + currentYear} replace />*/}
      </Switch>
    </div>
  );
};

export default GrandLivreMainPanel;
