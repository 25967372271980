

const RapprochéBadge = ({ isRapproché }:{isRapproché: boolean}) => (
  <span>
    {isRapproché ? (
      <span className="badge bg-secondary">Rapproché</span>
    ) : (
      <span className="badge bg-warning">Non rapproché</span>
    )}
  </span>
);

export default RapprochéBadge;
