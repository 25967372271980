import LogInput from "./LogInput";

const LogInputContainer = () => {
  return (
    <>
      <LogInput />
    </>
  );
};

export default LogInputContainer;
