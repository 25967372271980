import FactureRèglementLine from "./FactureRèglementLine";
import { integerCentToFrenchFinanString } from "vincent-utils";
import type {Facture2} from "compta-shared";
import DateDisplayerComp from "../../utils/components/DateDisplayerComp.tsx";
import RapprochéBadge from "./RapprochéBadge.tsx";

interface FactureCompProps {
  facture: Facture2;
  onFactureClicked: () => void;
}

const FactureComp = (props: FactureCompProps) => {
  const { facture, onFactureClicked } = props;
  const { libellé, date, banqueId, mode } = facture;
  const { règlements } = facture;
  const reglComps = règlements
    ? règlements.map((regl, idx) => (
        <FactureRèglementLine
          key={idx + "reglComps"}
          montant={integerCentToFrenchFinanString(regl.montantCent)}
          date={regl.date}
          banqueId={regl.banqueId || ""}
        />
      ))
    : null;
  return (
    <div onClick={onFactureClicked} style={{ cursor: "pointer" }}>
      {date ? <DateDisplayerComp date={date} /> : ""} {libellé}{" "}
      {integerCentToFrenchFinanString(facture.montantCent)} {mode}{" "}
      {!règlements ? <RapprochéBadge isRapproché={!!banqueId} /> : ""}
      <div>{reglComps}</div>
    </div>
  );
};

export default FactureComp;
