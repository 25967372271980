import { useEffect, useState } from "react";

const useRecetteDepense = (
  montantCent: number,
  onMontantCentChange: (value: number) => void
) => {
  const [recetteCent, setRecetteCent] = useState<number | null>(null);
  const [dépenseCent, setDépenseCent] = useState<number | null>(null);
  useEffect(() => {
    if (montantCent > 0) {
      setRecetteCent(montantCent);
      setDépenseCent(null);
    } else if (montantCent < 0) {
      setRecetteCent(null);
      setDépenseCent(-montantCent);
    } else {
      setRecetteCent(null);
      setDépenseCent(null);
    }
  }, [montantCent]);
  const onRecetteChange = (value: number | null) => {
    if (value === null || value === 0) {
      if (recetteCent !== null && dépenseCent === null) {
        setRecetteCent(null);
        onMontantCentChange(0);
        return;
      }
      if (dépenseCent) {
        setRecetteCent(null);
        return;
      }
      return; // on ne change rien
    }
    setRecetteCent(value);
    setDépenseCent(null);
    onMontantCentChange(value);
    return;
  };
  const onDépenseChange = (value: number | null) => {
    if (value === null || value === 0) {
      if (dépenseCent !== null && recetteCent === null) {
        setDépenseCent(null);
        onMontantCentChange(0);
        return;
      }
      if (recetteCent) {
        setDépenseCent(null);
        return;
      }
      return;
    }
    setRecetteCent(null);
    setDépenseCent(value);
    onMontantCentChange(-value);
    return;
  };
  return {
    recetteCent,
    dépenseCent,
    onDépenseChange,
    onRecetteChange
  };
};

export default useRecetteDepense;
