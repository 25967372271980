import { utils } from "vincent-utils";

const devHost = import.meta.env.VITE_DEV_HOST || "";

export default async function fetcher(
  url: string,
  paramsObj: { [key: string]: any },
  headersObj: { [key: string]: any },
  isBlob?: boolean,
) {
  const cleanedUrl = url.replace(/^\//, "");
  const params = new URLSearchParams({
    ...paramsObj,
  });
  console.log("params", params.toString());
  const headers = new Headers();
  Object.keys(headersObj).forEach((key) => {
    headers.set(key, headersObj[key]);
  });
  // headers.set(
  //   "Authorization",
  //   "Basic " + btoa(apiAuth.userId + ":" + apiAuth.token)
  // );
  console.log(
    "request url",
    `${devHost ? devHost + "/" : "/"}${cleanedUrl}?${params.toString()}`,
  );
  const response = await fetch(
    `${devHost ? devHost + "/" : "/"}${cleanedUrl}?${params.toString()}`,
    {
      headers,
      mode: "cors",
      credentials: "include",
    },
  );
  console.log("url", response.url);
  console.log("headers", response.headers);
  if (response.ok) {
    if (isBlob) {
      const fileNameHeader = response.headers.get("Content-Disposition");
      const fileName = fileNameHeader?.split("filename=")[1] || "";

      return {
        blob: await response.blob(),
        fileName,
      };
    }
    const json = await response.text();
    return JSON.parse(json, utils.jsonDateReviver);
  } else {
    throw new Error(`invalide json dans fetch ${url}?${params.toString()}`);
  }
}
