import React from "react";
import { formaters, integerCentToFrenchFinanString } from "vincent-utils";
import {BanqueLine2} from "compta-shared";

type BanqueLineCompProps = {
  banqueLine: BanqueLine2;
  selected: boolean;
  onClick: () => void;
  onNotHonorairesClick: (_id: string) => void;
};

const BanqueLineComp = (props: BanqueLineCompProps) => {
  const banqueLine = props.banqueLine;
  const { _id, date, libelle } = banqueLine;
  const montantCent =
    banqueLine.montantCent;
  const cbBrut = banqueLine.type === "remiseCartes" ? banqueLine.remiseCBBrut : null;
  const { onClick, onNotHonorairesClick } = props;
  const handleOnNotHonorairesClick = (_id: string) => (
    e: React.SyntheticEvent
  ) => {
    e.stopPropagation();
    onNotHonorairesClick(_id);
  };
  return (
    <li
      className={"list-group-item" + " " + (props.selected ? "active" : "")}
      onClick={onClick}
      style={{ cursor: "pointer" }}
    >
      {formaters.formatDateAsDDN(date)} {libelle} {" "}
      {integerCentToFrenchFinanString(montantCent)}{" "}
      {cbBrut ? "brut : " + integerCentToFrenchFinanString(cbBrut) : ""}{" "}
      <span
        className="badge bg-secondary"
        style={{ cursor: "pointer" }}
        onClick={handleOnNotHonorairesClick(_id)}
      >
        Pas des honoraires ?
      </span>
    </li>
  );
};

export default BanqueLineComp;
