import { Line } from "rc-progress";

interface FileUploadLineProps {
  fileName: string;
  progress: number;
  hasError: boolean;
  hasSuccess: boolean;
}

const FileUploadLine = (props: FileUploadLineProps) => {
  const { hasError, hasSuccess, fileName, progress } = props;
  const color = hasSuccess ? "#00E500" : hasError ? "#E50000" : "#2db7f5";
  return (
    <div style={{ display: "flex" }}>
      <span style={{ flex: "1 auto" }}>{fileName}</span>
      <span>
        <Line
          percent={progress}
          strokeColor={color}
          strokeWidth={8}
          style={{ width: "100px" }}
        />
      </span>
    </div>
  );
};

export default FileUploadLine;
