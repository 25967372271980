import { Route, useLocation } from "wouter";
import RapprocheComp from "./RapprocheComp.tsx";

const RapprochementPanelNames = [
  "rapprocheVirements",
  "rapprocheCartes",
  "rapprocheCheques",
  "olderHonoraires",
] as const;
export type RapprochementPanelComps = (typeof RapprochementPanelNames)[number];

const RapprochementMainComp = () => {
  const [location, setLocation] = useLocation();

  const handleSelectPanel = (panel: RapprochementPanelComps) => {
    setLocation("/" + panel, { replace: true });
  };

  const panelSelected = location.split("/")[1] as RapprochementPanelComps;

  return (
    <div>
      <div className="pb-2">
        <div>
          <div className="btn-group">
            <button
              type="button"
              className={
                "btn" +
                " " +
                (panelSelected === "rapprocheVirements"
                  ? "btn-primary"
                  : "btn-secondary")
              }
              onClick={() => handleSelectPanel("rapprocheVirements")}
            >
              Rapproche Virements
            </button>
            <button
              type="button"
              className={
                "btn" +
                " " +
                (panelSelected === "rapprocheCartes"
                  ? "btn-primary"
                  : "btn-secondary")
              }
              onClick={() => handleSelectPanel("rapprocheCartes")}
            >
              Rapproche Cartes
            </button>
            <button
              type="button"
              className={
                "btn" +
                " " +
                (panelSelected === "rapprocheCheques"
                  ? "btn-primary"
                  : "btn-secondary")
              }
              onClick={() => handleSelectPanel("rapprocheCheques")}
            >
              Rapproche Chèques
            </button>
          </div>
        </div>
      </div>
      <Route path="/:panel" nest>
        <RapprocheComp panel={panelSelected} />
      </Route>
    </div>
  );
};

export default RapprochementMainComp;
