import FloatCentDisplayer from "./FloatCentDisplayer";
import type { BanqueLine2} from "compta-shared";
import DateDisplayerComp from "../../utils/components/DateDisplayerComp.tsx";

type DepenseLineProps = {
  selected: boolean;
  onVentiler: (id: string) => void;
} & BanqueLine2;

const DepenseLine = (props: DepenseLineProps) => {
  const montantCent = props.montantCent;
  const {selected, date, libelle, onVentiler, _id} = props;
  return (
    <li className={"list-group-item" + " " + (selected ? "active" : "")}>
      {date ? <DateDisplayerComp date={date} /> : ""} {libelle}{" "}
      <FloatCentDisplayer cent={montantCent}/>
      <span
        className={"badge bg-secondary"}
        onClick={onVentiler.bind(null, _id)}
        color="secondary"
        style={{ cursor: "pointer" }}
      >
        Ventiler
      </span>
    </li>
  );
};

export default DepenseLine;
