import useImmobilisation from "../useImmobilisation";
import _ from "underts";
import AmmortissementLines from "./AmmortissementLines";
import {Immobilisation, ImmobilisationDTO} from "compta-shared";
import ValidatedInput from "../../utils/components/ValidatedInput.tsx";
import FloatInput from "../../utils/components/FloatInput.tsx";
import DateInput from "../../utils/components/DateInput.tsx";
import {validateImmobilisation} from "../immobilisationsValidate.ts";

interface ImmobilisationCaracsProps {
  immobilisation: Immobilisation | null;
  onCommitImmobilisation: (immobilisation: ImmobilisationDTO) => void;
}

const ImmobilisationCaracs = (props: ImmobilisationCaracsProps) => {
  const {
    immobilisation,
    onSetName,
    onSetMode,
    onSetYears,
    onSetPrincipalCent,
    onSetStartDate,
    onRecalculate,
    onModifyAmortissementFromLine,
    onRecalcFromLine,
    showRecalculateButton,
    isDirty: isTouched,
    lineDirtyIndex
  } = useImmobilisation(props.immobilisation);
  const isReadyToCommit =
    immobilisation.amortissementLines.length > 0 &&
    !showRecalculateButton &&
    lineDirtyIndex === null;
  return (
    <>
      <div className="col-4">
        <div className="card">
          <div
            className={
              "card-header" + (props.immobilisation ? "" : " text-bg-success")
            }
          >
            <h4 className="card-title pt-2">
              {props.immobilisation
                ? "Paramètres de l'immobilisation"
                : "Nouvelle immobilisation"}
            </h4>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-12">
                <label htmlFor="immobilisationName">Nom</label>
                <ValidatedInput
                  invalidMessage={"L'immobilisation doit avoir un nom"}
                  isValid={!!immobilisation.name}
                  value={immobilisation.name}
                  onChange={onSetName}
                  placeholder="Nom ou description de l'immobilisation"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <label htmlFor="immobilisationPrincipal">
                  Montant total amorti
                </label>
                <FloatInput
                  valueCent={immobilisation.principalCent}
                  onValueCentChange={onSetPrincipalCent}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-auto">
                <label htmlFor="immobilisationMode">Mode d'amortissement</label>
                <select
                  id={"immobilisationMode"}
                  className={`form-control form-select ${
                    isTouched
                      ? immobilisation.mode === null
                        ? "is-invalid"
                        : "is-valid"
                      : ""
                  }`}
                  value={immobilisation.mode || ""}
                  onChange={e => {
                    onSetMode(
                      e.target.value
                        ? (e.target.value as "linéaire" | "dégressif")
                        : null
                    );
                  }}
                >
                  <option value={""}>Choisir un mode d'amortissement</option>
                  <option value="linéaire">Linéaire</option>
                  <option value="dégressif">Dégressif</option>
                </select>
              </div>
            </div>
            <div className="row">
              <div className="col-auto">
                <label htmlFor="immobilisationYears">
                  Durée d'amortissement
                </label>
                <select
                  id={"immobilisationYears"}
                  className={`form-control form-select ${
                    isTouched
                      ? immobilisation.years === null
                        ? "is-invalid"
                        : "is-valid"
                      : ""
                  }`}
                  value={immobilisation.years || ""}
                  onChange={e => {
                    onSetYears(
                      e.target.value ? parseInt(e.target.value) : null
                    );
                  }}
                >
                  <option value={""}>Choisir une durée</option>
                  {_.range(1, 21)
                    .map(year => year.toString())
                    .map(year => (
                      <option key={year + "yearKey"} value={year}>
                        {year}
                      </option>
                    ))}
                </select>
              </div>
            </div>
            <div className="row">
              <div className={"col-6"}>
                <label htmlFor="immobilisationStartDate">
                  Date de mise en service
                </label>
                <DateInput
                  isValid={!!immobilisation.startDate}
                  date={immobilisation.startDate}
                  onDateChange={onSetStartDate}
                />
              </div>
            </div>
            {showRecalculateButton ? (
              <div className="row mt-2">
                <div className="col-md-2">
                  <button className="btn btn-primary" onClick={onRecalculate}>
                    Créer/Mettre à jour
                  </button>
                </div>
              </div>
            ) : null}
            {isReadyToCommit ? (
              <div className="row mt-2">
                <div className="col-md-2">
                  <button
                    className={"btn btn-primary"}
                    onClick={() =>
                      props.onCommitImmobilisation(
                        validateImmobilisation(immobilisation)
                      )
                    }
                  >
                    Enregistrer
                  </button>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      {immobilisation.amortissementLines.length > 0 ? (
        <div className={"row mt-2"}>
          <div className={"col-12"}>
            <AmmortissementLines
              lines={immobilisation.amortissementLines}
              onModifyAmortissementAtLine={onModifyAmortissementFromLine}
              startOfDirtyLines={lineDirtyIndex}
              onRecalcFromLine={onRecalcFromLine}
            />
          </div>
        </div>
      ) : null}
    </>
  );
};

export default ImmobilisationCaracs;
