import {trpc} from "../main/components/ProviderContainer.tsx";
import SlashDateFrenchDisplay from "../utils/components/SlashDateFrenchDisplay.tsx";

interface AllLoansPanelProps {
  onSelectLoan: (loanId: string) => void;
  selectedLoanId: string | null;
}
const AllLoansPanel = (props: AllLoansPanelProps) => {
  const {data} = trpc.loan.getAll.useQuery();
  const loans = data || [];
  return (
    <div>
      <div className="row mt-2">
        <div className="col col-md-auto">
          <div className="card">
            <div className="card-header">Tous les emprunts</div>
            <div className="card-body">
              <table className="table">
                <tbody>
                  {loans.map(loan => (
                    <tr
                      key={loan._id}
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        props.onSelectLoan(loan._id as string);
                      }}
                      className={
                        loan._id === props.selectedLoanId
                          ? "table-primary"
                          : "table-default"
                      }
                    >
                      <td>
                        <SlashDateFrenchDisplay date={loan.startDate} />
                      </td>

                      <td>{loan.name}</td>
                      <td>{loan.principalCent} </td>
                      <td>{loan.months + " mois"}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllLoansPanel;
