import FileUploadLine from "./FileUploadLine";
import RelevesAvailable from "./RelevesAvailable";
import _ from "underts";



interface FileUploadProps {
  uploadState: {
    uploadState: "started" | "stopped" | "error" | "success";
    currentFiles: string[];
    progress: {
      [fileName: string]: number;
    };
    filesWithError: string[];
    completedFiles: string[];
    order: {
      [fileName: string]: number;
    };
  };
  startUpload: (file: File[]) => void;
  currentYear: number;
}

const FileUpload = (props: FileUploadProps) => {
  const { uploadState, startUpload } = props;
  console.log("uploadState: ", uploadState);
  //TODO tous ces traitements devraient probablement être faits dans un selector
  //TODO les fichiers peuvent être à la fois dans success et error trouver solution
  const filesComp = uploadState.currentFiles
    .sort((a, b) => uploadState.order[a] - uploadState.order[b])
    .map((fileName, idx) => (
      <FileUploadLine
        key={"FileUploadLine" + idx}
        fileName={fileName}
        progress={uploadState.progress[fileName]}
        hasError={uploadState.filesWithError.indexOf(fileName) !== -1}
        hasSuccess={uploadState.completedFiles.indexOf(fileName) !== -1}
      />
    ));
  const completedFilesComps = uploadState.completedFiles.map(fileName => (
    <FileUploadLine
      key={"CompletedFileKey" + fileName}
      fileName={fileName}
      progress={100}
      hasError={false}
      hasSuccess={true}
    />
  ));
  const filesWithErrorComps = uploadState.filesWithError.map(fileName => (
    <FileUploadLine
      key={"errorFileKey" + fileName}
      fileName={fileName}
      progress={100}
      hasError={true}
      hasSuccess={false}
    />
  ));
  return (
    <div>
      <input
        id="fileInput"
        multiple
        type="file"
        onChange={event => {
          if (event?.currentTarget?.files) {
            startUpload([].slice.call(event.currentTarget.files));
          }
        }}
      />
      <div style={{ display: "flex", flexDirection: "column", width: "600px" }}>
        {filesComp}
        {completedFilesComps}
        {filesWithErrorComps}
      </div>
      <div>
        <RelevesAvailable
          years={_.range(props.currentYear - 5, props.currentYear + 1)}
        />
      </div>
    </div>
  );
};

export default FileUpload;
