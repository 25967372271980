import LoginSwitcher from "./modules/main/components/LoginSwitcher";

const Root = () => {
  return (
    <div className="container-fluid">
      <LoginSwitcher />
    </div>
  );
};

export default Root;
