import { Button } from "react-bootstrap";
import { PlusSquare, DashSquare, ArrowDownSquare } from "react-bootstrap-icons";
import { BanqueLine2, LoanPayment} from "compta-shared";
import FloatInput from "../utils/components/FloatInput.tsx";
import DateInput from "../utils/components/DateInput.tsx";
import IntegerInput from "../utils/components/IntegerInput.tsx";

type EcheancierLineProps = {
  payment: LoanPayment;
} & EcheancierLineCallbacks & {
    banqueLine: BanqueLine2 | null;
  } & EcheancierLineExtraState;

interface EcheancierLineExtraState {
  recalcEnabled: boolean;
}
interface EcheancierLineCallbacks {
  onChange: (
    item: "principalCent" | "interestCent" | "assuranceCent",
    value: number | null
  ) => void;
  onAddLine: () => void;
  onRemoveLine: () => void;
  onRecalcNextLines: () => void;
}
const EcheancierLine = (props: EcheancierLineProps) => {
  return (
    <tr>
      <td>
        <Button variant="light" onClick={props.onAddLine}>
          <PlusSquare />
        </Button>
      </td>
      <td>
        <Button variant="light" onClick={props.onRemoveLine}>
          <DashSquare />
        </Button>
      </td>
      <td>
        <Button
          disabled={!props.recalcEnabled}
          variant={props.recalcEnabled ? "danger" : "light"}
          onClick={props.onRecalcNextLines}
        >
          <ArrowDownSquare />
        </Button>
      </td>
      <td>
        <IntegerInput disabled value={props.payment.number} />
      </td>
      <td>
        <DateInput disabled date={props.payment.date} />
      </td>
      <td>
        <FloatInput valueCent={props.payment.monthlyPaymentCent} disabled />
      </td>
      <td>
        <FloatInput
          onValueCentChange={valueCent =>
            props.onChange("principalCent", valueCent)
          }
          valueCent={props.payment.principalCent}
        />
      </td>
      <td>
        <FloatInput
          onValueCentChange={valueCent =>
            props.onChange("interestCent", valueCent)
          }
          valueCent={props.payment.interestCent}
        />
      </td>
      <td>
        <FloatInput disabled valueCent={props.payment.remainingPrincipalCent} />
      </td>
      <td>
        <FloatInput
          onValueCentChange={valueCent =>
            props.onChange("assuranceCent", valueCent)
          }
          valueCent={props.payment.assuranceCent}
        />
      </td>
      <td>
        <DateInput
          disabled
          date={props.banqueLine ? props.banqueLine.date : null}
        />
      </td>
      <td>
        <input
          disabled
          value={props.banqueLine ? props.banqueLine.libelle : ""}
        />
      </td>
    </tr>
  );
};

export default EcheancierLine;
