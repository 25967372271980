import React, { useEffect } from "react";
import {
  floatStringToIntegerCent,
  integerCentToFloatString
} from "vincent-utils";

type FloatInputProps = FloatDisabledInputProps | FloatEnabledInputProps;

interface FloatDisabledInputProps {
  valueCent: number | null;
  id?: string;
  disabled: true;
  placeholder?: string;
}

interface FloatEnabledInputProps {
  onValueCentChange: (valueCent: number | null) => void;
  onType?: (valueString: string) => void;
  valueCent: number | null;
  id?: string;
  placeholder?: string;
  disabled?: false;
}
const FloatInput = (props: FloatInputProps) => {
  const [floatString, setFloatString] = React.useState(
    (typeof props.valueCent === "number" &&
      integerCentToFloatString(props.valueCent)) ||
      ""
  );
  useEffect(() => {
    setFloatString(
      (typeof props.valueCent === "number" &&
        integerCentToFloatString(props.valueCent)) ||
        ""
    );
  }, [props.valueCent]);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    setFloatString(value);
    if ("onType" in props && props.onType) {
      props.onType(value);
    }
    // if (value === "") {
    //   props.onValueCentChange(0);
    // }
    // const valueCent = floatStringToIntegerCent(value);
    // console.log("valueCent", valueCent);
    // if (!valueCent) {
    //   return;
    // }
    // props.onValueCentChange(valueCent);
  };
  const handleBlur = () => {
    if ("disabled" in props && props.disabled) {
      return;
    }
    const valueCent = floatStringToIntegerCent(floatString);

    //pas besoin de faire quelque chose si la valeur n'a pas changé
    if (valueCent === props.valueCent) {
      return;
    }
    if (floatString === "") {
      props.onValueCentChange(null);
    }

    if (valueCent == null) {
      return;
    }
    if (valueCent !== props.valueCent) {
      props.onValueCentChange(valueCent);
    }
    setFloatString(integerCentToFloatString(valueCent) as string); //on remet la valeur sanitized
  };
  const isValidValue = floatStringToIntegerCent(floatString) != null;
  const isTouched = floatString !== "";
  return (
    <input
      type="text"
      id={props.id}
      className={`form-control ${
        isTouched && !isValidValue ? "is-invalid" : isTouched ? "is-valid" : ""
      }`}
      value={floatString}
      onChange={handleChange}
      onBlur={handleBlur}
      disabled={"disabled" in props && props.disabled}
      placeholder={props.placeholder}
    />
  );
};

export default FloatInput;
