import { formaters, integerCentToFrenchFinanString } from "vincent-utils";

interface HonorairesCompProps {
  libellé: string;
  date: Date;
  montantCent: number;
  "n° de bordereau de banque"?: string;
  proposed: number[];
  combinaisonSelected: number | null;
  onSelectCombinaison: (number: number) => void;
  checked: boolean;
  onToggleCheck: () => void;
}

const HonorairesComp = (props: HonorairesCompProps) => {
  const bordereau = props["n° de bordereau de banque"];
  const {
    date,
    libellé,
    montantCent,
    proposed,
    combinaisonSelected,
    onSelectCombinaison,
  } = props;
  const isInvolved = props.proposed.length > 0;
  return (
    <li className="list-group-item">
      <div className="form-check">
        <input
          type="checkbox"
          className="form-check-input"
          checked={props.checked}
          onChange={props.onToggleCheck}
        />
        <label
          className={"form-check-label " + (isInvolved ? "text-primary" : "")}
        >
          {formaters.formatDateAsDDN(date)} {libellé} - {bordereau}
          {" - "}
          {integerCentToFrenchFinanString(montantCent)}{" "}
        </label>{" "}
        {proposed.map((number) => (
          <span key={"propBadgeKey" + number}>
            <span
              className={
                "badge" +
                " " +
                (combinaisonSelected === number ? "bg-primary" : "bg-secondary")
              }
              onClick={() => onSelectCombinaison(number)}
              color={combinaisonSelected === number ? "primary" : "secondary"}
              style={{ cursor: "pointer" }}
            >
              {number}
            </span>{" "}
          </span>
        ))}
      </div>
    </li>
  );
};

export default HonorairesComp;
