import * as React from "react";

interface TagsInputProps {
  onSearch: (search: string) => void;
  onKeyUp: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
  onRemoveTag: (tag: string) => void;
  value: string;
  tags: string[];
  width?: number;
}
const TagsInput = (props: TagsInputProps) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.onSearch(event.target.value);
  };
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Tab") {
      event.preventDefault();
    }
  };
  return (
    <div className="fakeInput" style={{ width: props.width }}>
      {props.tags.map(tag => (
        <span className="label label-default tags" key={tag + "_key"}>
          {tag}
          <span
            className="deleteTag"
            onClick={() => props.onRemoveTag(tag)}
            key={tag + "_cat_delete"}
          >
            X
          </span>
        </span>
      ))}
      <input
        type="text"
        className="receiverInput"
        id="{{id}}"
        value={props.value}
        onChange={handleChange}
        onKeyUp={props.onKeyUp}
        onKeyDown={handleKeyDown}
        onBlur={props.onBlur}
        autoComplete="off"
      />
    </div>
  );
};
export default TagsInput;
