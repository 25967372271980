import useRecetteDepense from "../hooks/useRecetteDepense";
import { RèglementState } from "../hooks/useFacture";
import DateInput from "../../utils/components/DateInput.tsx";
import FloatInput from "../../utils/components/FloatInput.tsx";

interface FactureRèglementInputLineProps {
  date: Date | null;
  montantCent: number | null;
  onChange<ITEM extends "date" | "montantCent">(
    item: ITEM,
    value: RèglementState[ITEM]
  ): void;
  onLineType: () => void;
  onVentilationClick: () => void;
  onDeleteRèglement: () => void;
  hasVentilations: boolean;
}
const FactureRèglementInputLine = ({
  date,
  montantCent,
  hasVentilations,
  onChange,
  onVentilationClick,
  onLineType,
  onDeleteRèglement
}: FactureRèglementInputLineProps) => {
  const onDateChange = (date: Date | null) => onChange("date", date);

  const { onRecetteChange, onDépenseChange } = useRecetteDepense(
    montantCent || 0,
    value => onChange("montantCent", value)
  );
  return (
    <div className="row">
      <div className="col-4 col-md-2">
        <label className="form-label">Date prévue</label>
        <DateInput
          date={date}
          onDateChange={onDateChange}
          isValid={!!date}
          invalidMessage="Il manque la date"
        />
      </div>
      <div className="col-3 col-md-2">
        <label className="form-label">Dépense</label>
        <FloatInput
          valueCent={montantCent && montantCent < 0 ? -montantCent : null}
          onValueCentChange={onDépenseChange}
          onType={onLineType}
        />
      </div>
      <div className="col-3 col-md-2">
        <label className="form-label">Recette</label>
        <FloatInput
          valueCent={montantCent && montantCent > 0 ? montantCent : null}
          onValueCentChange={onRecetteChange}
          onType={onLineType}
        />
      </div>
      <span className="w-auto mt-auto mb-0">
        <button
          type="button"
          className="btn btn-danger"
          onClick={onDeleteRèglement}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-trash"
            viewBox="0 0 16 16"
          >
            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
            <path
              fillRule="evenodd"
              d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
            />
          </svg>
        </button>
      </span>
      <span className="w-auto mt-auto mb-0">
        {montantCent ? (
          <button
            type={"button"}
            className={
              "btn" + " " + (hasVentilations ? "btn-success" : "btn-primary")
            }
            onClick={onVentilationClick}
          >
            Ventilation
          </button>
        ) : (
          ""
        )}
      </span>
    </div>
  );
};

export default FactureRèglementInputLine;
