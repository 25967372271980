import React, { useEffect, useState } from "react";
import { filterCompte } from "../factures/processing/compteSearcher";
import useRecetteDepense from "../factures/hooks/useRecetteDepense";
import logInputValidator from "./validators/logInputValidator";
import { ManualComptaLine } from "compta-shared";
import { trpc } from "../main/components/ProviderContainer.tsx";
import DateInput from "../utils/components/DateInput.tsx";
import FloatInput from "../utils/components/FloatInput.tsx";
import modes from "../main/consts/modes.ts";
import AutoComplete from "../utils/components/AutoCompleteComp/AutoComplete2.tsx";

export interface LogInputState {
  _id?: string;
  owner?: string;
  montantCent: number;
  compteNumber: number | null;
  compteName: string;
  libellé: string;
  date: Date | null;
  mode: null | ManualComptaLine["mode"];
}

const getDefaultState = () => {
  return {
    montantCent: 0,
    compteNumber: null,
    compteName: "",
    libellé: "",
    date: null,
    mode: null,
  };
};

const LogInput = () => {
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [selectedManualLineId, setSelectedManualLineId] = useState<
    string | null
  >(null);
  const [showAlertSuccess, setShowAlertSuccess] = React.useState(false);
  const [showAlertError, setShowAlertError] = React.useState(false);
  const { data: existingAction } = trpc.manualComptaLine.getById.useQuery(
    selectedManualLineId as string,
    {
      enabled: !!selectedManualLineId,
    },
  );
  const [logInput, setLogInput] = React.useState<LogInputState>(
    existingAction || getDefaultState(),
  );

  const commitManualComptaLineMutation =
    trpc.manualComptaLine.commit.useMutation({
      onSuccess: () => {
        setIsSubmitting(false);
        setShowAlertSuccess(true);
        setTimeout(() => {
          setShowAlertSuccess(false);
        }, 3000);
      },
      onError: () => {
        setIsSubmitting(false);
        setShowAlertError(true);
        setTimeout(() => {
          setShowAlertError(false);
        }, 3000);
      },
    });

  const onSubmit = async (comptaLine: LogInputState) => {
    if (comptaLine.mode === null) {
      throw new Error("no valid mode while submitting");
    }
    if (!comptaLine.compteNumber) {
      throw new Error("no valid compteNumber while submitting");
    }
    if (!comptaLine.date) {
      throw new Error("no valid date while submitting");
    }
    commitManualComptaLineMutation.mutate({
      ...comptaLine,
      mode: comptaLine.mode, //pour que TS soit content
      compteNumber: comptaLine.compteNumber!,
      date: comptaLine.date!,
    });
  };

  useEffect(() => {
    if (existingAction) {
      setLogInput(existingAction);
    }
  }, [JSON.stringify(existingAction)]);
  const onSetMontantCent = (montantCent: number) => {
    setLogInput((logInput) => ({ ...logInput, montantCent }));
  };
  const onSetCompte = (compte: string) => {
    const compteNumber = compte.split(" ")[0];
    const compteName = compte.split(" ").slice(1).join(" ");
    setLogInput({
      ...logInput,
      compteName: compteName || "",
      compteNumber: compteNumber ? parseInt(compteNumber) : null,
    });
  };

  const onSetLibellé = (libellé: string) => {
    setLogInput((logInput) => ({ ...logInput, libellé }));
  };

  const onSetDate = (date: Date | null) => {
    setLogInput((logInput) => ({ ...logInput, date }));
  };

  const onSetMode = (mode: ManualComptaLine["mode"]) => {
    setLogInput((logInput) => ({ ...logInput, mode }));
  };
  const handleSubmit = () => {
    setIsSubmitting(true);
    onSubmit(logInput);
  };

  const onCancel = () => {
    setSelectedManualLineId(null);
    setLogInput(getDefaultState());
  };
  const [modeSearch, setModeSearch] = React.useState("");
  const modeSearchReg = new RegExp(modeSearch, "i");
  const modeSearchCandidates = modeSearch
    ? modes.filter((mode) => modeSearchReg.test(mode))
    : [];

  const [compteSearch, setCompteSearch] = React.useState("");
  const compteSearchCandidates = filterCompte(compteSearch);
  const { recetteCent, onRecetteChange, onDépenseChange, dépenseCent } =
    useRecetteDepense(logInput.montantCent, onSetMontantCent);
  const logInputErrors = logInputValidator(logInput);
  return (
    <div>
      <div className="card">
        <div className="card-header">
          <span className="fs-4">
            {logInput._id
              ? "Edition d'une ligne de compta"
              : "Entrée d'une nouvelle ligne de compta"}
          </span>
          {logInputErrors.map((error) => (
            <span key={error + "key"} className="badge text-bg-danger m-2">
              {error}
            </span>
          ))}
        </div>
        <div className="card-body">
          <form>
            <div className="row">
              <div className="col-6 col-md-2">
                <label>Libellé</label>
                <input
                  className="form-control"
                  value={logInput.libellé}
                  onChange={(e) => {
                    onSetLibellé(e.target.value);
                  }}
                ></input>
              </div>
              <div className="col-5 col-md-2">
                <label>Date règlement</label>
                <DateInput
                  onDateChange={onSetDate}
                  date={logInput.date || null}
                  isValid={!!logInput.date}
                  invalidMessage="Une date doit être fournie"
                />
              </div>
              <div className="col-4 col-md-1">
                <label>Dépense</label>
                <FloatInput
                  valueCent={dépenseCent}
                  onValueCentChange={onDépenseChange}
                />
              </div>
              <div className="col-4 col-md-1">
                <label>Recette</label>
                <FloatInput
                  valueCent={recetteCent}
                  onValueCentChange={onRecetteChange}
                />
              </div>
              <div className="col-5 col-md-2">
                <label>Mode</label>
                <AutoComplete
                  candidates={modeSearchCandidates}
                  onSetSearch={(value) => setModeSearch(value || "")}
                  onSetValue={(value) =>
                    onSetMode(
                      (value as (typeof modeSearchCandidates)[number]) || null,
                    )
                  }
                  value={logInput.mode || ""}
                  isValid={!!logInput.mode}
                  invalidMessage={"Un mode doit être fourni"}
                  tagsOn={false}
                  acceptNewValues={false}
                />
              </div>

              <div className="col-8 col-md-2">
                <label>Compte</label>
                <AutoComplete
                  candidates={compteSearchCandidates}
                  onSetSearch={(value) => setCompteSearch(value || "")}
                  onSetValue={onSetCompte}
                  value={
                    logInput.compteNumber
                      ? logInput.compteNumber + " " + logInput.compteName
                      : ""
                  }
                  className="form-control"
                  tagsOn={false}
                  acceptNewValues={false}
                  isValid={!!logInput.compteName}
                  invalidMessage={"Un compte doit être fourni"}
                />
              </div>
            </div>
            <div className="row pt-2">
              <div className="col-1">
                <button
                  type="button"
                  className="btn btn-primary mb-2"
                  onClick={handleSubmit}
                  disabled={logInputErrors.length > 0 || isSubmitting}
                >
                  Enregistrer
                </button>
              </div>
              <div className="col-1">
                <button
                  type="button"
                  className="btn btn-warning mb-2"
                  onClick={onCancel}
                >
                  Abandonner
                </button>
              </div>
            </div>
          </form>
          {showAlertSuccess && (
            <div className="alert alert-success" role="alert">
              Enregistré avec succès
            </div>
          )}
          {showAlertError && (
            <div className="alert alert-danger" role="alert">
              Il y a eu un problème lors de l'enregistrement de la facture
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default LogInput;
