import DepensesList from "../components/DepensesList";
import YearChooser from "../components/YearChooser";
import _ from "underts";
import { trpc } from "../../main/components/ProviderContainer.tsx";
import { Redirect, Route, Switch, useLocation, useSearch } from "wouter";
import VentilationsContainer from "../../rapprochements/containers/VentilationsContainer.tsx";

interface UnjustifiedLinesContainerProps {
  mode: "depenses" | "recettes";
  defaultYear: number;
}

const UnjustifiedLinesContainer = (props: UnjustifiedLinesContainerProps) => {
  const [location, setLocation] = useLocation();
  const yearSelected = parseInt(location.split("/")[1]);
  const handleSelectYear = (year: number) => {
    setLocation("/" + year, { replace: true });
  };

  const handleCancelVentilations = () => {
    const state = history.state;
    if (state === "back") {
      console.log("back");
      history.back();
      return;
    }
    history.back();
    //setShowVentilationsModal(false);
    //setSimilarLinesIds([]);
  };

  const handleOnVentiler = (id: string) => {
    setLocation(`/${yearSelected}/ventilationsEdit?banqueId=${id}`);
  };

  const searchString = useSearch();
  const params = new URLSearchParams(searchString);

  const actionId = params.get("actionId");
  const banqueId = params.get("banqueId");

  const { data: unjustifiedDepenses } =
    trpc.banque.getUnjustifiedDepenses.useQuery(undefined, {
      enabled: props.mode === "depenses",
    });
  const { data: unjustifiedRecettes } =
    trpc.banque.getUnjustifiedEntrées.useQuery(undefined, {
      enabled: props.mode === "recettes",
    });
  const unjustifiedFiltered =
    (props.mode === "depenses" &&
      unjustifiedDepenses?.filter(
        (banqueLine) => banqueLine.date.getFullYear() === yearSelected,
      )) ||
    (props.mode === "recettes" &&
      unjustifiedRecettes?.filter(
        (banqueLine) => banqueLine.date.getFullYear() === yearSelected,
      )) ||
    [];
  const currentYear = new Date().getFullYear();
  const previousYears = _.range(5, -1, -1).map((i) => currentYear - i);
  return (
    <div>
      <YearChooser
        yearSelected={yearSelected}
        yearChoices={previousYears}
        onYearSelected={handleSelectYear}
      />
      <Switch>
        <Route path="/:year" nest>
          <DepensesList
            banqueLines={unjustifiedFiltered}
            onVentiler={handleOnVentiler}
          />
          <Route path={"/ventilationsEdit"}>
            {banqueId ? (
              <VentilationsContainer
                banqueLineId={banqueId}
                actionId={actionId}
                similarLinesIds={[]}
                onCancelVentilations={handleCancelVentilations}
                key={banqueId}
              />
            ) : null}
          </Route>
        </Route>
        <Redirect to={"/" + props.defaultYear} />
      </Switch>
    </div>
  );
};

export default UnjustifiedLinesContainer;
