import {DateTime} from "luxon";
import {LoanPayment} from "compta-shared";
import _ from "underts";
const getNextDate = (startDate: Date, previousDate: Date) => {
    const targetDayNumber = DateTime.fromJSDate(startDate).day;
    const newDate = DateTime.fromJSDate(previousDate).plus({ months: 1 });
    function walkUntilDayNumber(date: DateTime): DateTime {
        if (date.day === targetDayNumber) {
            return date;
        }
        const newDateTry = date.plus({ days: 1 });
        if (newDateTry.month !== date.month) {
            return date;
        }
        return walkUntilDayNumber(date.plus({ days: 1 }));
    }
    return walkUntilDayNumber(newDate).toJSDate();
};


const nextEcheanceFromPrevious = (
    annuelRateCent: number,
    monthlyPaymentCent: number,
    annualInsuranceRateCent: number,
    startDate: Date,
    {
        remainingPrincipalCent,
        assuranceCent,
        number,
        date
    }: {
        principalCent: number;
        interestCent: number;
        remainingPrincipalCent: number;
        assuranceCent: number;
        number: number;
        date: Date;
    }
) => {
    const tauxMensuelCent = annuelRateCent / 12;
    const interestCent = Math.round(
        (tauxMensuelCent / 10000) * remainingPrincipalCent
    );
    console.log("remainingPrincipalCent", remainingPrincipalCent);
    const actualAssuranceCent = annualInsuranceRateCent
        ? Math.round(
            remainingPrincipalCent * (annualInsuranceRateCent / 12 / 10000)
        )
        : assuranceCent;

    console.log("remainingPrincipalCent", remainingPrincipalCent);
    // const alternativeMonthInterestRate = (1 +tauxAnnuelCent /10000) **(1/12) - 1;
    // const alternativeInterestCent = Math.round(alternativeMonthInterestRate * remainingCapital);
    const capitalAmortiCent =
        monthlyPaymentCent - interestCent - actualAssuranceCent;

    const nextPrincipalCent = remainingPrincipalCent - capitalAmortiCent;
    if (nextPrincipalCent < 0) {
        return {
            remainingPrincipalCent: 0,
            principalCent: remainingPrincipalCent,
            interestCent: interestCent,
            assuranceCent: actualAssuranceCent,
            number: number + 1,
            monthlyPaymentCent:
                remainingPrincipalCent + interestCent + actualAssuranceCent,
            date: DateTime.fromJSDate(date)
                .plus({ months: 1 })
                .toJSDate()
        };
    }
    return {
        principalCent: capitalAmortiCent,
        interestCent,
        remainingPrincipalCent: nextPrincipalCent,
        assuranceCent: actualAssuranceCent,
        monthlyPaymentCent,
        number: number + 1,
        date: getNextDate(startDate, date)
    };
};

const echeanceCompositionCalculator = (
    totalPrincipalCent: number,
    tauxAnnuelCent: number,
    mensualiteCent: number,
    echeanceNumber: number,
    assuranceMensuelleCent: number,
    startDate: Date,
    tauxAssuranceAnnuelleCent?: number
) => {
    const result = [...Array(echeanceNumber).keys()].reduce(
        (acc) => {
            return nextEcheanceFromPrevious(
                tauxAnnuelCent,
                mensualiteCent,
                tauxAssuranceAnnuelleCent || 0,
                startDate,
                acc
            );
        },
        //seed
        {
            principalCent: totalPrincipalCent,
            interestCent: 0,
            remainingPrincipalCent: totalPrincipalCent,
            assuranceCent: assuranceMensuelleCent,
            number: 0,
            date: startDate
        }
    );
    return result;
};

const echeancierBuilder = (
    principalCent: number,
    annualRateCent: number,
    echeancesCount: number,
    monthlyPaymentCent: number,
    assuranceMensuelleCent: number,
    assuranceRateCent: number,
    startDate: Date,
    startNumber?: number
) => {
    const range = [...Array(echeancesCount).keys()];
    return _.tail<LoanPayment>(
        range.reduce(
            (acc) => {
                const previousEcheance = acc[acc.length - 1];
                const echeance = nextEcheanceFromPrevious(
                    annualRateCent,
                    monthlyPaymentCent,
                    assuranceRateCent,
                    startDate,
                    previousEcheance
                );
                return [...acc, echeance];
            },
            [
                {
                    principalCent: principalCent,
                    interestCent: 0,
                    remainingPrincipalCent: principalCent,
                    assuranceCent: assuranceMensuelleCent,
                    number: startNumber ? startNumber - 1 : 0,
                    date: DateTime.fromJSDate(startDate)
                        .minus({ months: 1 })
                        .toJSDate(),
                    monthlyPaymentCent: monthlyPaymentCent
                }
            ]
        )
    );
};

const mensualiteCalc = (
    principalCent: number,
    months: number,
    annualRateCent: number
) => {
    const monthlyRate1 = annualRateCent / 12 / 10000;

    return Math.round(
        principalCent * (monthlyRate1 / (1 - (1 + monthlyRate1) ** -months))
    );
};

export {echeancierBuilder, echeanceCompositionCalculator, mensualiteCalc}