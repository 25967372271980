import { formaters, integerCentToFrenchFinanString } from "vincent-utils";
import {Immobilisation} from "compta-shared";

interface ImmobilisationSelectorProps {
  immobilisations: Immobilisation[];
  onSelectImmoId: (immobilisationId: string) => void;
  selectedImmoId: string | null;
}
const ImmobilisationSelector = (props: ImmobilisationSelectorProps) => {
  return (
    <div className="card">
      <div className="card-header">
        <h4 className="card-title pt-2">Liste des immobilisations</h4>
      </div>
      <div className="card-body">
        <table className="table">
          <tbody>
            {props.immobilisations.map(immobilisation => (
              <tr
                className={
                  immobilisation._id === props.selectedImmoId
                    ? "table-primary"
                    : "table-default"
                }
                key={immobilisation._id}
                onClick={() => props.onSelectImmoId(immobilisation._id)}
                style={{ cursor: "pointer" }}
              >
                <td>{formaters.formatDateAsDDN(immobilisation.startDate)}</td>
                <td> {immobilisation.name} </td>
                <td>
                  {immobilisation.years}
                  {" ans "}
                </td>
                <td>
                  {integerCentToFrenchFinanString(immobilisation.principalCent)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ImmobilisationSelector;
