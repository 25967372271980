import {GrandLivreRecord} from "compta-shared";

const RecordTypeBadge = (props: { record: GrandLivreRecord }) => {
  const { record } = props;
  let typeName = "";
  let className = "";
  if (record.factureId) {
    typeName = "Facture";
    className = "text-bg-primary";
  }
  if (record.actionId) {
    typeName = "Ventilation manuelle";
    className = "text-bg-warning";
  }
  if (record.loanId) {
    typeName = "Emprunt";
    className = "text-bg-success";
  }
  if (record.logTresoId) {
    typeName = "Journal importé";
    className = "text-bg-info";
  }
  return <span className={"badge " + className}>{typeName}</span>;
};

export default RecordTypeBadge;
