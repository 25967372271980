import React from "react";
import KilometersPanel from "./KilometersPanel";
import MadelinPanel from "./MadelinPanel";

type ODsPanels = "kilometers" | "madelin";

interface ODsContainerProps {
  yearSelected: number;
}
const ODsContainer = (props: ODsContainerProps) => {
  const [panel, setPanel] = React.useState<ODsPanels>("kilometers");
  return (
    <>
      <div className="row">
        <div className="btn-group">
          <button
            type="button"
            className={
              "btn " +
              (panel === "kilometers" ? "btn-primary" : "btn-secondary")
            }
            onClick={() => setPanel("kilometers")}
          >
            Kilomètres et blanchissage
          </button>
          <button
            type="button"
            className={
              "btn " + (panel === "madelin" ? "btn-primary" : "btn-secondary")
            }
            onClick={() => setPanel("madelin")}
          >
            Madelin
          </button>
        </div>
      </div>
      <div className="row">
        {panel === "kilometers" && (
          <KilometersPanel yearSelected={props.yearSelected} />
        )}
      </div>
      <div className="row">
        {panel === "madelin" && (
          <MadelinPanel yearSelected={props.yearSelected} />
        )}
      </div>
    </>
  );
};

export default ODsContainer;
