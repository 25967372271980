import React, { useEffect } from "react";
import { formaters } from "vincent-utils";

type DateInputProps = DisabledDateInputProps | EnabledDateInputProps;

interface DisabledDateInputProps {
  date: Date | null;
  id?: string;
  disabled: true;
  isValid?: boolean;
  invalidMessage?: string;
}

interface EnabledDateInputProps {
  date: Date | null;
  id?: string;
  onDateChange: (date: Date | null) => void;
  onType?: (valueString: string) => void;
  isValid?: boolean;
  invalidMessage?: string;
  disabled?: false;
}

const DateInput = (props: DateInputProps) => {
  const [dateString, setDateString] = React.useState(
    (props.date && formaters.formatDateAsDDN(props.date)) || ""
  );
  useEffect(() => {
    setDateString((props.date && formaters.formatDateAsDDN(props.date)) || "");
    if (props.date) {
    }
  }, [props.date?.getTime()]);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setDateString(value);
    if ("onType" in props && props.onType) {
      props.onType(value);
    }
  };
  const handleBlur = () => {
    if (!("onDateChange" in props)) {
      return;
    }
    if (dateString === "") {
      props.onDateChange(null);
      return;
    }
    const date = formaters.parseDateString(dateString);
    if (!date) {
      setDateString(
        (props.date && formaters.formatDateAsDDN(props.date)) || ""
      );
      return;
    }
    props.onDateChange(date);
    setDateString(formaters.formatDateAsDDN(date));
  };
  const isValidValue = formaters.parseDateString(dateString) != null;
  const isTouched =
    dateString !== "" &&
    (props.date === null ||
      dateString !== formaters.formatDateAsDDN(props.date) ||
      !!formaters.formatDateAsDDN(props.date)); //on est aussi touched quand une date est déjà fournie
  return (
    <>
      <input
        type="text"
        disabled={"disabled" in props && props.disabled}
        id={props.id}
        className={`form-control ${
          isTouched && !isValidValue
            ? "is-invalid"
            : isTouched
            ? "is-valid"
            : ""
        }`}
        value={dateString}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      {isTouched && !props.isValid && !!props.invalidMessage && (
        <div className="invalid-feedback d-block">{props.invalidMessage}</div>
      )}
    </>
  );
};

export default DateInput;
