import React, { useEffect } from "react";
import type { WithPartial } from "underts";
import { trpc } from "../main/components/ProviderContainer.tsx";
import type { CSGCRDS } from "compta-shared";
import FloatInput from "../utils/components/FloatInput.tsx";
import useAlert from "../utils/hooks/useAlert.ts";
import SuccessErrorComp from "../utils/components/SuccessErrorComp.tsx";

export type CSGCRDSState = WithPartial<CSGCRDS, "_id" | "owner">;
const getDefaultState = (): CSGCRDSState => ({
  years: {} as CSGCRDS["years"],
});

interface CSGCRDSPanelProps {
  yearSelected: number;
}

const CSGCRDSPanel = (props: CSGCRDSPanelProps) => {
  const { data: csgcrdsObject } = trpc.csgcrds.get.useQuery();
  // const { data: settings } = trpc.setting.get.useQuery();
  const [csgcrdsState, setCsgcrdsState] = React.useState<CSGCRDSState>(
    csgcrdsObject || getDefaultState()
  );
  // const yearChoices =
  //   (settings &&
  //     Object.keys(settings.years).map((year) => parseInt(year, 10))) ||
  //   [];
  // useEffect(() => {
  //   setYearSelected(_.last(yearChoices) || null);
  // }, [JSON.stringify(yearChoices)]);
  useEffect(() => {
    setCsgcrdsState(csgcrdsObject || getDefaultState());
  }, [JSON.stringify(csgcrdsObject)]);

  const onSetRegul = (montantCent: number | null) => {
    // if (!yearSelected) {
    //   throw new Error("yearSelected is null");
    // }
    setCsgcrdsState((state) => ({
      ...state,
      years: {
        ...state.years,
        [props.yearSelected]: {
          ...state.years[props.yearSelected],
          regulMontantCent: montantCent || 0,
        },
      },
    }));
  };

  const onSetProvision = (montantCent: number | null) => {
    // if (!yearSelected) {
    //   throw new Error("yearSelected is null");
    // }
    setCsgcrdsState((state) => ({
      ...state,
      years: {
        ...state.years,
        [props.yearSelected]: {
          ...state.years[props.yearSelected],
          provisionMontantCent: montantCent || 0,
        },
      },
    }));
  };

  const onSetCFP = (montantCent: number | null) => {
    // if (!yearSelected) {
    //   throw new Error("yearSelected is null");
    // }
    setCsgcrdsState((state) => ({
      ...state,
      years: {
        ...state.years,
        [props.yearSelected]: {
          ...state.years[props.yearSelected],
          CFPMontantCent: montantCent || 0,
        },
      },
    }));
  };

  const onSetCURPS = (montantCent: number | null) => {
    // if (!yearSelected) {
    //   throw new Error("yearSelected is null");
    // }
    setCsgcrdsState((state) => ({
      ...state,
      years: {
        ...state.years,
        [props.yearSelected]: {
          ...state.years[props.yearSelected],
          CURPSMontantCent: montantCent || 0,
        },
      },
    }));
  };

  const utils = trpc.useContext();

  const commitCsgCrdsMutation = trpc.csgcrds.commit.useMutation({
    onSuccess: () => {
      onIsSuccess("Enregistré avec succès");
      utils.csgcrds.invalidate();
    },
    onError: () => {
      onIsError("Erreur lors de l'enregistrement");
    },
  });
  const handleCommit = () => {
    console.log("commit", csgcrdsState);
    commitCsgCrdsMutation.mutate(csgcrdsState);
  };

  const yearValues = props.yearSelected
    ? csgcrdsState.years[props.yearSelected.toString()]
    : null;

  const regulMontantCent = yearValues?.regulMontantCent || 0;
  const deductibleRegulMontantCent = Math.round((regulMontantCent * 6.8) / 9.7);
  const nonDeductibleRegulMontantCent = Math.round(
    regulMontantCent - deductibleRegulMontantCent
  );
  const provisionMontantCent = yearValues?.provisionMontantCent || 0;
  const deductibleProvisionMontantCent = Math.round(
    (provisionMontantCent * 6.8) / 9.7
  );
  const nonDeductibleProvisionMontantCent = Math.round(
    provisionMontantCent - deductibleProvisionMontantCent
  );
  const totalCSGCRDS = regulMontantCent + provisionMontantCent;
  const totalDeductible =
    deductibleRegulMontantCent + deductibleProvisionMontantCent;
  const totalNonDeductible =
    nonDeductibleRegulMontantCent + nonDeductibleProvisionMontantCent;
  const {
    successMessage,
    errorMessage,
    isSuccess,
    isError,
    onDismissError,
    onIsSuccess,
    onIsError,
  } = useAlert();
  return (
    <div className="card">
      <div className="card-header">
        <h3 className="card-title">
          Ventilations cotisations personnelles URSSAF
        </h3>
      </div>
      <div className="card-body">
        {/*<div className="row mt-2">*/}
        {/*  <div className="col-md-2">*/}
        {/*    <select*/}
        {/*      className="form-select"*/}
        {/*      value={yearSelected || ""}*/}
        {/*      onChange={(e) => {*/}
        {/*        const year = parseInt(e.target.value, 10);*/}
        {/*        setYearSelected(year);*/}
        {/*      }}*/}
        {/*    >*/}
        {/*      {yearChoices.map((year) => (*/}
        {/*        <option key={year} value={year}>*/}
        {/*          {year}*/}
        {/*        </option>*/}
        {/*      ))}*/}
        {/*    </select>*/}
        {/*  </div>*/}
        {/*</div>*/}
        <div className="row mt-2">
          <div className="col-md-2">
            <label className="form-label">
              CFP payée en {props.yearSelected}
            </label>
            <FloatInput
              valueCent={yearValues?.CFPMontantCent || 0}
              onValueCentChange={onSetCFP}
            />
            <div id="CFPHelpBlock" className="form-text">
              En ajoutant éventuellement la régularisation de l'année{" "}
              {props.yearSelected - 1}
            </div>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-md-2">
            <label className="form-label">
              CURPS payée en {props.yearSelected}
            </label>
            <FloatInput
              valueCent={yearValues?.CURPSMontantCent || 0}
              onValueCentChange={onSetCURPS}
            />
            <div id="CURPSHelpBlock" className="form-text">
              En ajoutant éventuellement la régularisation de l'année{" "}
              {props.yearSelected - 1}
            </div>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-md-2">
            <label className="form-label">
              CSGCRDS régularisée pour {props.yearSelected - 1}
            </label>
            <FloatInput
              valueCent={yearValues?.regulMontantCent || 0}
              onValueCentChange={onSetRegul}
            />
          </div>
          <div className="col-md-2">
            <label className="form-label">Dont déductible</label>
            <FloatInput
              valueCent={deductibleRegulMontantCent}
              disabled={true}
            />
          </div>
          <div className="col-md-2">
            <label className="form-label">Non déductible</label>
            <FloatInput
              valueCent={nonDeductibleRegulMontantCent}
              disabled={true}
            />
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-md-2">
            <label className="form-label">
              CSGCRDS provisionnelle pour {props.yearSelected}
            </label>
            <FloatInput
              valueCent={yearValues?.provisionMontantCent || 0}
              onValueCentChange={onSetProvision}
            />
          </div>
          <div className="col-md-2">
            <label className="form-label">Dont déductible</label>
            <FloatInput
              valueCent={deductibleProvisionMontantCent}
              disabled={true}
            />
          </div>
          <div className="col-md-2">
            <label className="form-label">Non déductible</label>
            <FloatInput
              valueCent={nonDeductibleProvisionMontantCent}
              disabled={true}
            />
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-md-2">
            <label className="form-label">
              Total CSGCRDS payé en {props.yearSelected}
            </label>
            <FloatInput valueCent={totalCSGCRDS} disabled={true} />
          </div>
          <div className="col-md-2">
            <label className="form-label">
              Total déductible payé en {props.yearSelected}
            </label>
            <FloatInput valueCent={totalDeductible} disabled={true} />
          </div>
          <div className="col-md-2">
            <label className="form-label">
              Total non déductible payé en {props.yearSelected}
            </label>
            <FloatInput valueCent={totalNonDeductible} disabled={true} />
          </div>
        </div>

        <div className="row mt-2">
          <div className="col-md-2">
            <button className="btn btn-primary" onClick={handleCommit}>
              Enregistrer
            </button>
          </div>
        </div>
        <SuccessErrorComp
          showAlertSuccess={isSuccess}
          showAlertError={isError}
          successMessage={successMessage}
          errorMessage={errorMessage}
          onDismissError={onDismissError}
        />
      </div>
    </div>
  );
};

export default CSGCRDSPanel;
