import AllLoansPanel from "./AllLoansPanel";
import EcheancierPanel from "./EcheancierPanel";
import _ from "underts";
import { trpc } from "../main/components/ProviderContainer.tsx";
import { Route, useLocation, useSearch } from "wouter";

const LoansContainer = () => {
  // const [isAdding, setIsAdding] = React.useState(false);
  const [location, setLocation] = useLocation();
  console.log("location", location);
  const queryString = useSearch();
  console.log("queryString", queryString);
  const params = new URLSearchParams(queryString);
  const selectedLoanId = params.get("loanId");
  const { data } = trpc.loan.getAll.useQuery();
  const loans = data || [];
  const selectedLoan = loans.find((loan) => loan._id === selectedLoanId);
  const banqueLinesIds = selectedLoan
    ? _.compact(selectedLoan.payments.map((payment) => payment.banqueId))
    : [];
  const { data: banqueLines } = trpc.banque.getBanqueLines.useQuery(
    banqueLinesIds,
    { enabled: !!banqueLinesIds.length },
  );
  // const { data: banqueLines } = useQuery(
  //   ["banque", JSON.stringify(banqueLinesIds)],
  //   () => getBanqueLines(banqueLinesIds),
  //   { enabled: !!banqueLinesIds.length }
  // );
  console.log("selectedLoanId", selectedLoanId);
  console.log("selectedLoan", selectedLoan);
  const isAdding = location === "/loanEdit" && !selectedLoanId;
  const isAddingOrEditing = location !== "/";
  const handleCreateLoan = () => {
    setLocation("/loanEdit");
  };
  const handleCancel = () => {
    setLocation("/");
  };

  const handleSelectLoanId = (id: string) => {
    setLocation(`/loanEdit?loanId=${id}`);
  };
  return (
    <div>
      <AllLoansPanel
        onSelectLoan={handleSelectLoanId}
        selectedLoanId={selectedLoanId}
      />
      {isAdding ? (
        <button
          type="button"
          className="btn btn-danger mt-2"
          onClick={handleCancel}
        >
          Annuler les modifications
        </button>
      ) : (
        <button
          type="button"
          className="btn btn-primary mt-2"
          onClick={handleCreateLoan}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-file-earmark-plus"
            viewBox="0 0 16 16"
          >
            <path d="M8 6.5a.5.5 0 0 1 .5.5v1.5H10a.5.5 0 0 1 0 1H8.5V11a.5.5 0 0 1-1 0V9.5H6a.5.5 0 0 1 0-1h1.5V7a.5.5 0 0 1 .5-.5z" />
            <path d="M14 4.5V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h5.5L14 4.5zm-3 0A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5h-2z" />
          </svg>
          Créer un emprunt
        </button>
      )}
      <Route path="/loanEdit">
        {(isAddingOrEditing || selectedLoanId) && (
          <EcheancierPanel
            loan={selectedLoan || null}
            banqueLines={banqueLines || []}
            isAdding={isAdding}
            onCancel={handleCancel}
            key={selectedLoanId}
          />
        )}
      </Route>
    </div>
  );
};

export default LoansContainer;
