import { LogInputState } from "../LogInput";

const logInputValidator = (logInput: LogInputState) => {
  const errors: string[] = [];
  if (!logInput.date) {
    errors.push("La date est obligatoire");
  }
  if (!logInput.montantCent) {
    errors.push("On doit avoir une recette ou une dépense");
  }
  if (!logInput.libellé) {
    errors.push("Le libellé est obligatoire");
  }
  if (!logInput.mode) {
    errors.push("Le mode est obligatoire");
  }
  if (!logInput.compteName || !logInput.compteNumber) {
    errors.push("Le compte est obligatoire");
  }
  return errors;
};

export default logInputValidator;
