import { ImmobilisationState } from "./useImmobilisation";
import _ from "underts";
import { v4 as uuid } from "uuid";
import {AmortissementLine} from "compta-shared";

const calcNextLinearLine = (
  previousAmortissements: number,
  yearAmmortissement: number,
  remainingPrincipal: number,
  previousYearNumber: number
) => {
  const amortissementCent = Math.min(yearAmmortissement, remainingPrincipal);
  const amortissementPrécédentCent = previousAmortissements;
  const amortissementCumuléCent = amortissementCent + previousAmortissements;
  const valeurRésiduelleCent = remainingPrincipal - amortissementCent;
  return {
    amortissementCent,
    amortissementPrécédentCent,
    amortissementCumuléCent,
    valeurRésiduelleCent,
    yearStartDate: new Date(previousYearNumber + 1, 0, 1),
    _id: uuid()
  };
};

export const walkLinearAmortissement = (
  result: AmortissementLine[],
  yearAmmortissement: number,
  remainingPrincipal: number
): AmortissementLine[] => {
  if (remainingPrincipal === 0) {
    return result;
  }
  const previousLine = _.last(result);
  if (!previousLine) {
    throw new Error("previousLine is undefined");
  }
  const newLine = calcNextLinearLine(
    previousLine.amortissementCumuléCent,
    yearAmmortissement,
    previousLine.valeurRésiduelleCent,
    previousLine.yearStartDate.getFullYear()
  );
  return walkLinearAmortissement(
    [...result, newLine],
    yearAmmortissement,
    newLine.valeurRésiduelleCent
  );
};

export const walkDegressiveAmortissement = (
  result: AmortissementLine[],
  totalYears: number,
  currentYearIndex: number,
  remainingPrincipal: number,
  tauxDégressif: number
): AmortissementLine[] => {
  if (remainingPrincipal === 0) {
    return result;
  }
  const previousLine = _.last(result);
  if (!previousLine) {
    throw new Error("previousLine is undefined");
  }
  const linearAmortissementCent = Math.round(
    (1 / (totalYears - currentYearIndex)) * previousLine.valeurRésiduelleCent
  );
  const degressiveAmortissementCent = Math.max(
    Math.round(previousLine.valeurRésiduelleCent * tauxDégressif),
    linearAmortissementCent
  );

  const amortissementCent = Math.min(
    degressiveAmortissementCent,
    remainingPrincipal
  );
  const newLine = calcNextLinearLine(
    previousLine.amortissementCumuléCent,
    amortissementCent,
    previousLine.valeurRésiduelleCent,
    previousLine.yearStartDate.getFullYear()
  );
  return walkDegressiveAmortissement(
    [...result, newLine],
    totalYears,
    currentYearIndex + 1,
    newLine.valeurRésiduelleCent,
    tauxDégressif
  );
};
const calcAmortissementLinéaire = (immostate: ImmobilisationState) => {
  const yearRate = 1 / immostate.years!;
  const remainingMonthDays =
    30 - immostate.startDate!.getDate() > 0
      ? 30 - immostate.startDate!.getDate()
      : 0;
  const remainingYearDays =
    remainingMonthDays + (12 - immostate.startDate!.getMonth() - 1) * 30;
  const firstYearAmortissementCent = Math.round(
    (remainingYearDays / 360) * immostate.principalCent! * yearRate
  );
  const remainingPrincipalCent =
    immostate.principalCent! - firstYearAmortissementCent;
  return walkLinearAmortissement(
    [
      {
        amortissementCent: firstYearAmortissementCent,
        amortissementPrécédentCent: 0,
        amortissementCumuléCent: firstYearAmortissementCent,
        valeurRésiduelleCent: remainingPrincipalCent,
        yearStartDate: immostate.startDate!,
        _id: uuid()
      }
    ],
    Math.round(immostate.principalCent! * yearRate),
    remainingPrincipalCent
  );
};

export const calcTauxDégressif = (years: number) => {
  const coeffDégressif = years! >= 5 ? (years! > 6 ? 2.25 : 1.75) : 1.25;
  return (1 / years!) * coeffDégressif;
};

const calcAmortissementDégressif = (immostate: ImmobilisationState) => {
  const coeffDégressif =
    immostate.years! >= 5 ? (immostate.years! > 6 ? 2.25 : 1.75) : 1.25;
  const tauxDégressif = (1 / immostate.years!) * coeffDégressif;
  const remainingMonths = 12 - immostate.startDate!.getMonth() - 1 + 1; //on part du début du mois en cours
  const firstYearAmortissementCent = Math.round(
    (remainingMonths / 12) * immostate.principalCent! * tauxDégressif
  );
  const remainingPrincipalCent =
    immostate.principalCent! - firstYearAmortissementCent;
  const firstYear = {
    amortissementCent: firstYearAmortissementCent,
    amortissementPrécédentCent: 0,
    amortissementCumuléCent: firstYearAmortissementCent,
    valeurRésiduelleCent: remainingPrincipalCent,
    yearStartDate: immostate.startDate!,
    _id: uuid()
  };
  return walkDegressiveAmortissement(
    [firstYear],
    immostate.years!,
    1,
    remainingPrincipalCent,
    tauxDégressif
  );
};

export default (immostate: ImmobilisationState) => {
  if (immostate.mode === "linéaire") {
    return calcAmortissementLinéaire(immostate);
  }
  return calcAmortissementDégressif(immostate);
};
