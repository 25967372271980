import React from "react";
import ImmobilisationCaracs from "./ImmobilisationCaracs";
import ImmobilisationSelector from "./ImmobilisationSelector";
import { ImmobilisationDTO } from "compta-shared";
import { trpc } from "../../main/components/ProviderContainer";

const ImmobilisationsContainer = () => {
  const [isAdding, setIsAdding] = React.useState(false);
  console.log("isAdding", isAdding);
  const [immobilisationId, setImmobilisationId] = React.useState<string | null>(
    null
  );
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [showAlertSuccess, setShowAlertSuccess] = React.useState(false);
  const [showAlertError, setShowAlertError] = React.useState(false);
  const { data: immobilisations } = trpc.immobilisation.get.useQuery();
  console.log("immobilisationId", immobilisationId);
  const immobilisationSelected =
    (immobilisations &&
      immobilisations.find((i) => i._id === immobilisationId)) ||
    null;

  console.log("immobilisationSelected", immobilisationSelected);

  const utils = trpc.useContext();
  const immobilisationCommitMutation = trpc.immobilisation.commit.useMutation({
    onSuccess: () => {
      setShowAlertSuccess(true);
      setTimeout(() => {
        setShowAlertSuccess(false);
      }, 3000);

      utils.immobilisation.invalidate();
      setIsAdding(false);
      setImmobilisationId(null);
      setIsSubmitting(false);
    },
    onError: () => {
      setShowAlertError(true);
      setTimeout(() => {
        setShowAlertError(false);
      }, 3000);
      setIsSubmitting(false);
    },
  });
  const onCommitImmobilisation = async (immobilisation: ImmobilisationDTO) => {
    if (isSubmitting) return;
    setIsSubmitting(true);
    immobilisationCommitMutation.mutate(immobilisation);
  };

  const onSelectedImmoId = (immobilisationId: string) => {
    setImmobilisationId(immobilisationId);
    setIsAdding(false);
  };

  const handleAddImmo = () => {
    setImmobilisationId(null);
    setIsAdding(true);
  };

  const handleCancel = () => {
    setIsAdding(false);
    setImmobilisationId(null);
  };

  return (
    <>
      <div className="col col-md-6 col-lg-4 mt-2">
        <ImmobilisationSelector
          immobilisations={immobilisations || []}
          selectedImmoId={immobilisationId}
          onSelectImmoId={onSelectedImmoId}
        />
      </div>
      {isAdding ? (
        <button className="btn btn-danger mt-2" onClick={handleCancel}>
          Annuler les modifications
        </button>
      ) : (
        <button className="btn btn-primary mt-2" onClick={handleAddImmo}>
          Créer immobilisation
        </button>
      )}
      {isAdding || immobilisationId ? (
        <div className="mt-2">
          <ImmobilisationCaracs
            immobilisation={immobilisationSelected}
            onCommitImmobilisation={onCommitImmobilisation}
          />
        </div>
      ) : (
        ""
      )}
      {showAlertSuccess && (
        <div className="alert alert-success" role="alert">
          Enregistré avec succès
        </div>
      )}
      {showAlertError && (
        <div className="alert alert-danger" role="alert">
          Il y a eu un problème lors de l'enregistrement de la facture
        </div>
      )}
    </>
  );
};

export default ImmobilisationsContainer;
