import { z } from "zod";
import {ImmobilisationDTO} from "compta-shared";

type Implements<Model> = {
  [key in keyof Model]-?: undefined extends Model[key]
    ? null extends Model[key]
      ? z.ZodNullableType<z.ZodOptionalType<z.ZodType<Model[key]>>>
      : z.ZodOptionalType<z.ZodType<Model[key]>>
    : null extends Model[key]
    ? z.ZodNullableType<z.ZodType<Model[key]>>
    : z.ZodType<Model[key]>;
};

export function implement<Model = never>() {
  return {
    with: <
      Schema extends Implements<Model> &
        {
          [unknownKey in Exclude<keyof Schema, keyof Model>]: never;
        }
    >(
      schema: Schema
    ) => z.object(schema)
  };
}

const immobilisationLinesSchema = z.object({
  _id: z.string(),
  amortissementPrécédentCent: z
    .number()
    .int()
    .nonnegative(),
  amortissementCent: z
    .number()
    .int()
    .positive(),
  amortissementCumuléCent: z
    .number()
    .int()
    .nonnegative(),
  valeurRésiduelleCent: z
    .number()
    .int()
    .nonnegative(),
  yearStartDate: z.date()
});

const immobilisationSchema = implement<ImmobilisationDTO>().with({
  _id: z.string().nullable(),
  owner: z.string().nullable(),
  name: z.string(),
  principalCent: z
    .number()
    .int()
    .positive(),
  years: z
    .number()
    .int()
    .positive(),
  startDate: z.date(),
  mode: z.enum(["linéaire", "dégressif"]),
  amortissementLines: z.array(immobilisationLinesSchema)
});

export const validateImmobilisation = (immobilisation: unknown) => {
  return immobilisationSchema.parse(immobilisation);
};
