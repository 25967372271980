interface SuccessErrorCompProps {
  showAlertSuccess: boolean;
  showAlertError: boolean;
  successMessage: string;
  errorMessage: string;
  onDismissError: () => void;
}

const SuccessErrorComp = (props: SuccessErrorCompProps) => {
  const {
    showAlertSuccess,
    showAlertError,
    successMessage,
    errorMessage,
    onDismissError
  } = props;
  return (
    <>
      {showAlertSuccess && (
        <div className="alert alert-success mt-2" role="alert">
          {successMessage}
        </div>
      )}
      {showAlertError && (
        <div className="alert alert-danger mt-2" role="alert">
          <span>{errorMessage}</span>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="alert"
            aria-label="Close"
            onClick={onDismissError}
          ></button>
        </div>
      )}
    </>
  );
};

export default SuccessErrorComp;
