import { useState } from "react";
import FileUpload from "../components/FileUpload";
import { trpc } from "../../main/components/ProviderContainer.tsx";

const hostDirty = import.meta.env.VITE_DEV_HOST || window.location.host;
const mode = import.meta.env.MODE;
const prefix = mode === "development" ? "http://" : "https://";
const host = hostDirty.replace("http://", "");
const url = `${prefix}${host}/comptaRest/upload`;

type UploadState = {
  uploadState: "stopped" | "started" | "error" | "success";
  currentFiles: string[];
  progress: { [key: string]: number };
  completedFiles: string[];
  filesWithError: string[];
  order: { [key: string]: number };
  counter: number;
};

const useUploadState = () => {
  const [uploadState, setUploadState] = useState<UploadState>({
    uploadState: "stopped",
    currentFiles: [],
    progress: {},
    completedFiles: [],
    filesWithError: [],
    order: {},
    counter: 0,
  });

  const addFile = (file: File) => {
    setUploadState((uploadState: UploadState) => ({
      ...uploadState,
      currentFiles: [...uploadState.currentFiles, file.name],
      progress: { ...uploadState.progress, [file.name]: 0 },
      counter: uploadState.counter + 1,
      order: { ...uploadState.order, [file.name]: uploadState.counter },
    }));
  };

  const setProgress = (file: File, progress: number) => {
    setUploadState((uploadState: UploadState) => ({
      ...uploadState,
      progress: { ...uploadState.progress, [file.name]: progress },
    }));
  };

  const setUploaded = (file: File) => {
    setUploadState((uploadState: UploadState) => ({
      ...uploadState,
      currentFiles: uploadState.currentFiles.filter((f) => f !== file.name),
      completedFiles: [...uploadState.completedFiles, file.name],
    }));
  };

  const setErrored = (file: File) => {
    setUploadState((uploadState: UploadState) => ({
      ...uploadState,
      currentFiles: uploadState.currentFiles.filter((f) => f !== file.name),
      filesWithError: [...uploadState.filesWithError, file.name],
    }));
  };

  const utils = trpc.useContext();
  // const startUpload = (file: File) => {
  //   Relevés.insert(
  //     {
  //       file,
  //       chunkSize: "dynamic",
  //       onStart: () => addFile(file),
  //       onError: () => setErrored(file),
  //       onProgress: (progress: number) => setProgress(file, progress),
  //       onUploaded: () => {
  //         setUploaded(file);
  //         setTimeout(() => utils.banque.invalidate(), 2000); //hack, on met 2s pour que le serveur ait le temp de se mettre à jour
  //       }
  //     },
  //     true
  //   );
  // };

  const startMultipleUploads = (files: File[]) => {
    const data = new FormData();
    files.forEach((file, i) => {
      addFile(file);
      setProgress(file, 0);
      data.append(`file-${i}`, file, file.name);
    });

    // 👇 Uploading the files using the fetch API to the server
    fetch(url, {
      method: "POST",
      body: data,
      credentials: "include",
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        files.forEach(setUploaded);
        utils.banque.invalidate(); //todo mettre en place un vrai suivi de l'upload et du traitement des fichiers
      })
      .catch((err) => {
        console.error(err);
        setErrored(files[0]); //todo gérer plusieurs fichiers
      });
  };
  return {
    uploadState,
    startMultipleUploads,
  };
};

const UploadContainer = () => {
  const { startMultipleUploads, uploadState } = useUploadState();
  console.log("uploadState: ", uploadState);
  const currentYear = new Date().getFullYear();
  return (
    <FileUpload
      uploadState={uploadState}
      startUpload={startMultipleUploads}
      currentYear={currentYear}
    />
  );
};

export default UploadContainer;
