import { useState } from "react";

export default () => {
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const onIsSuccess = (message: string) => {
    setIsSuccess(true);
    setIsError(false);
    setErrorMessage("");
    setSuccessMessage(message);
    setTimeout(() => {
      setIsSuccess(false);
      setSuccessMessage("");
    }, 3000);
  };
  const onIsError = (message: string) => {
    setIsError(true);
    setErrorMessage(message);
  };
  const onDismissError = () => {
    setIsError(false);
    setErrorMessage("");
  };
  return {
    isSuccess,
    isError,
    successMessage,
    errorMessage,
    onIsSuccess,
    onIsError,
    onDismissError
  };
};
