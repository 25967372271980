import { atom } from "jotai";
import { User } from "compta-shared";

export const LoggedUserId = atom<string | null>(null);

export const LoggedUserAtom = atom<User | null>(null);

export const facturesPanelNames = [
  "factureInput",
  "allFactures",
  "allNonJustifiedDepenses",
  "allNonJustifiedEntrées",
] as const;

export const showVentilationsModalAtom = atom<boolean>(false);

export const yearSelectedAtom = atom<number | null>(new Date().getFullYear());

yearSelectedAtom.debugLabel = "yearSelectedAtom";

export const selectedManualComptaLineIdAtom = atom<string | null>(null);

export const selectedLoanIdAtom = atom<string | null>(null);
