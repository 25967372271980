interface YearChooserProps {
  yearSelected: number | null;
  yearChoices: number[];
  onYearSelected: (year: number) => void;
}

const YearChooser = (props: YearChooserProps) => {
  const selectedYear = props.yearSelected;
  return (
    <div className="btn-group">
      {props.yearChoices.map(year => (
        <button
          type="button"
          className={
            "btn" + (year === selectedYear ? " btn-primary" : " btn-secondary")
          }
          onClick={() => props.onYearSelected(year)}
          key={"year_button_key_" + year}
        >
          {year}
        </button>
      ))}
    </div>
  );
};

export default YearChooser;
