import AutoComplete2 from "../utils/components/AutoCompleteComp/AutoComplete2.tsx";
import _ from "underts";
import { formaters, integerCentToFrenchFinanString } from "vincent-utils";
import RecordTypeBadge from "./RecordTypeBadge.tsx";
import { useState } from "react";
import { consolidatedComptesNames, GrandLivreRecord } from "compta-shared";
import { trpc } from "../main/components/ProviderContainer.tsx";
import { Route, useLocation } from "wouter";
import styles from "./GrandLivreComp.module.css";

const consolidatedCompteNameToCompteNumber = (
  consolidatedCompteName: string,
) => {
  return parseInt(consolidatedCompteName.split(" ")[0]);
};

interface GrandLivreCompProps {
  yearSelected: number;
}

const UnlinkIcon = () => (
  <div className={styles.unlinkIcon}>
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Interface / Link_Horizontal_Off">
        <path
          id="Vector"
          d="M8 12H12M12 12L8 8M12 12L20 20M15 8H17C19.2091 8 21 9.79086 21 12C21 13.1947 20.4759 14.2671 19.6455 15M9 16H7C4.79086 16 3 14.2091 3 12C3 9.79086 4.79086 8 7 8H8M8 8L4 4"
          stroke="#000000"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
    </svg>
  </div>
);

const GrandLivreComp = (props: GrandLivreCompProps) => {
  const [location, setLocation] = useLocation();
  console.log("yearSelected in GrandLivreComp", props.yearSelected);

  const handleSetCompteSelected = (compte: string) => {
    setLocation("/" + consolidatedCompteNameToCompteNumber(compte), {
      replace: true,
    });
  };

  const compteNumberSelected = parseInt(location.split("/")[1]);
  console.log("compteNumberSelected", compteNumberSelected);
  const compteSelected =
    consolidatedComptesNames.find(
      (compte) =>
        compteNumberSelected === consolidatedCompteNameToCompteNumber(compte),
    ) || "";

  const onSetNextCompte = () => {
    const index = consolidatedComptesNames.findIndex(
      (compte) => compte === compteSelected,
    );
    if (index < consolidatedComptesNames.length - 1) {
      const _compteSelected = consolidatedComptesNames[index + 1];
      const compteNumber =
        consolidatedCompteNameToCompteNumber(_compteSelected);
      setLocation("/" + compteNumber, { replace: true });
    }
    return;
  };

  const onSetPrevCompte = () => {
    const index = consolidatedComptesNames.findIndex(
      (compte) => compte === compteSelected,
    );
    if (index > 0) {
      const _compteSelected = consolidatedComptesNames[index - 1];
      const compteNumber =
        consolidatedCompteNameToCompteNumber(_compteSelected);
      setLocation("/" + compteNumber, { replace: true });
    }
    return;
  };

  const [compteSearch, setCompteSearch] = useState<string>("");
  const re = new RegExp(compteSearch, "i");
  //en cas d'empty string, on ne renvoie rien sion la regex renvoie tout
  const foundComptes =
    (compteSearch && consolidatedComptesNames.filter((c) => c.match(re))) || [];

  const { data: records, isInitialLoading } =
    trpc.grandLivre.getCompteRecordsByYear.useQuery(
      {
        year: props.yearSelected as number,
        compteNum: consolidatedCompteNameToCompteNumber(compteSelected),
      },
      { enabled: !!compteSelected && !!props.yearSelected },
    );
  const total = records?.reduce((acc, r) => acc + r.montantCent, 0) || 0;
  const count = records?.length || 0;

  const unlinkMutation = trpc.rapprochement.unlinkRapprochement.useMutation();

  const handleUnlink = (banqueLineId: string, logLinesId: string) => {
    const result = confirm("Voulez-vous vraiment défaire ce rapprochement ?");
    if (!result) {
      return;
    }
    unlinkMutation.mutate({ banqueLineId, logLinesId });
  };

  // const selectBanqueId = useSetAtom(selectedBanqueLineIdAtom);
  // const selectedActionId = useSetAtom(selectedActionIdAtom);

  const handleOnRowClick = (record: GrandLivreRecord) => {
    if (!props.yearSelected || !compteSelected) {
      throw new Error(
        "yearSelected or compteSelected is not defined in handleOnRowClick",
      );
    }
    console.log("record", record);
    if (record.factureId) {
      setLocation("~/factures/factureInput?factureId=" + record.factureId, {
        state: "back",
      });
      // selectFactureId(record.factureId);
      // selectPanel("factures");
      // selectFacturePanel("factureInput");
      return;
    }
    if (record.actionId) {
      const year = record.DateRglt.getFullYear();

      if (record.montantCent < 0) {
        setLocation(
          `~/factures/allNonJustifiedDepenses/${year}/ventilationsEdit?actionId=${record.actionId}&banqueId=${record.banqueId}`,
          { state: "back" },
        );
        // selectFacturePanel("allNonJustifiedDepenses");
      }
      if (record.montantCent > 0) {
        setLocation(
          `~/factures/allNonJustifiedEntrees/${year}/ventilationsEdit?actionId=${record.actionId}&banqueId=${record.banqueId}`,
          { state: "back" },
        );
        // selectFacturePanel("allNonJustifiedEntrées");
      }
      return;
    }
    if (record.loanId) {
      setLocation("~/emprunts/loanEdit?loanId=" + record.loanId, {
        state: "back",
      });
      return;
    }
    if (record.logTresoId) {
      alert(
        "Pour modifier une ligne importée du journal de recettes/dépenses d'un autre logiciel, modifiez la ligne dans l'autre logiciel puis réimportez",
      );
    }
  };
  return (
    <div>
      <div className="pt-2">Choisissez un compte</div>
      <div className="row">
        <div className="col-xxl-4 col-xl-5 col-lg-6 col-md-8 col-11">
          <div className="input-group mt-2">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={onSetPrevCompte}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-arrow-left"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
                />
              </svg>
            </button>
            {/*<select*/}
            {/*  value={compteSelected}*/}
            {/*  onChange={onSelectCompte}*/}
            {/*  className="form-select"*/}
            {/*>*/}
            {/*  {["", ...consolidatedComptesNames].map(compte => (*/}
            {/*    <option key={compte}>{compte}</option>*/}
            {/*  ))}*/}
            {/*</select>*/}
            <AutoComplete2
              className="flex-grow-1"
              candidates={foundComptes}
              value={compteSelected}
              onSetSearch={(value) => setCompteSearch(value || "")}
              onSetValue={handleSetCompteSelected}
              tagsOn={false}
              acceptNewValues={false}
            />
            <button
              type="button"
              onClick={onSetNextCompte}
              className="btn btn-secondary"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-arrow-right"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
      <Route path="/:compteNumber">
        {isInitialLoading && <div className="spinner-border"></div>}
        {records && (
          <table className="table table-bordered mt-2 table-hover">
            <tbody>
              {_.sortBy(records, "DateRglt").map((r) => (
                <tr
                  key={r._id}
                  style={{ cursor: "pointer" }}
                  onClick={() => handleOnRowClick(r)}
                >
                  <td>{formaters.formatDateAsDDN(r.DateRglt)}</td>
                  <td>
                    {r.EcritureLib} <RecordTypeBadge record={r} />
                  </td>
                  <td>{r.ModeRglt}</td>

                  <td>{integerCentToFrenchFinanString(r.montantCent)}</td>
                  <td
                    className={styles.tdIcon}
                    onClick={(e) => {
                      if (r.banqueLines.length === 0 || !r.logTresoId) {
                        return;
                      }
                      e.stopPropagation();
                      r.banqueId &&
                        r.logTresoId &&
                        handleUnlink(r.banqueId, r.logTresoId);
                    }}
                  >
                    {r.banqueLines.length > 0 && r.logTresoId ? (
                      <UnlinkIcon />
                    ) : null}
                  </td>
                  <>
                    {r.banqueLines.length > 0 ? (
                      <>
                        <td>
                          {formaters.formatDateAsDDN(r.banqueLines[0].date)}
                        </td>
                        <td>{r.banqueLines[0].libelle}</td>
                        <td>
                          {("creditCent" in r.banqueLines[0] &&
                            integerCentToFrenchFinanString(
                              r.banqueLines[0].creditCent,
                            )) ||
                            ("debitCent" in r.banqueLines[0] &&
                              "-" +
                                integerCentToFrenchFinanString(
                                  r.banqueLines[0].debitCent,
                                ))}
                        </td>
                      </>
                    ) : (
                      <>
                        <td></td>
                        <td></td>
                      </>
                    )}
                  </>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </Route>
      <div>
        Total du compte pour la période :{" "}
        {integerCentToFrenchFinanString(total)}
      </div>
      <div>Nombre d'enregistrements pour la période : {count}</div>
    </div>
  );
};

export default GrandLivreComp;
