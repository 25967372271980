import {useState} from "react";
import poster from "../../../utils/poster.ts";

import {z} from "zod";

//TODO : à améliorer
const prefix = "comptaRest";

interface LoginScreenProps {
    callBack: () => void;
}

const LoginScreen = (props: LoginScreenProps) => {
    // const [email, setEmail] = useState("");
    // const [username, setUsername] = useState("");
    const [emailOrUsername, setEmailOrUsername] = useState("");
    const [password, setPassword] = useState("");
    const [hasErrors] = useState(false);
    const onSubmit = () => {
        if (
            z.string().email().safeParse(emailOrUsername).success &&
            z.string().min(6).safeParse(password).success
        ) {
            poster(`${prefix}/loginUser`, {email: emailOrUsername, password}).then(
                (response) => {
                    if (response) {
                        props.callBack();
                    }
                }
            );
            return;
        }
        if (
            z.string().min(4).safeParse(emailOrUsername).success &&
            z.string().min(6).safeParse(password).success
        ) {
            poster(`${prefix}/loginUser`, {
                username: emailOrUsername,
                password,
            }).then((response) => {
                if (response) {
                    props.callBack();
                }
            });
            return;
        }

        // Meteor.loginWithPassword(email, password, (error) => {
        //   if (error) {
        //     setHasError(true);
        //   }
        // });
    };
    // const isLoggingIn = useTracker(() => Meteor.loggingIn());
    const isLoggingIn = false;
    return (
        <div className="container-md align-items-center min-vh-100 min-vw-100">
            <div className="row justify-content-center">
                <div className="col-12 col-md-6">
                    <div className="card">
                        <div className="card-header">
                            <h2>Login COMPTA</h2>
                        </div>
                        <div className="card-body">
                            {hasErrors ? (
                                <div className="alert alert-danger" role="alert">
                                    Mauvaise combinaison email/mot de passe
                                </div>
                            ) : null}
                            <div className="mb-3">
                                <label htmlFor="emailInput" className="form-label">
                                    Email ou Nom d'utilisateur
                                </label>
                                <input
                                    type="email"
                                    className="form-control"
                                    id="emailInput"
                                    value={emailOrUsername}
                                    onChange={(e) => setEmailOrUsername(e.target.value)}
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="passwordInput" className="form-label">
                                    Mot de passe
                                </label>
                                <input
                                    type="password"
                                    className="form-control"
                                    id="passwordInput"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    onKeyDown={(e) => {
                                        if (e.key === "Enter") {
                                            onSubmit();
                                        }
                                    }}
                                />
                            </div>
                            <button
                                type="button"
                                className={"btn btn-primary"}
                                disabled={!emailOrUsername || !password || isLoggingIn}
                                onClick={onSubmit}
                            >
                                Entrer
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LoginScreen;
