import React, { useEffect } from "react";
import type { WithPartial } from "underts";
import _ from "underts";
import { DateTime } from "luxon";
import { formaters, integerCentToFrenchFinanString } from "vincent-utils";
import { Kilometers } from "compta-shared";
import { trpc } from "../main/components/ProviderContainer.tsx";
import useAlert from "../utils/hooks/useAlert.ts";
import FloatInput from "../utils/components/FloatInput.tsx";
import IntegerInput from "../utils/components/IntegerInput.tsx";
import SuccessErrorComp from "../utils/components/SuccessErrorComp.tsx";

export type KilometersState = WithPartial<Kilometers, "_id" | "owner">;

const getDefaultYear = (): KilometersState["years"][string] => ({
  months: _.range(0, 12).reduce(
    (obj, number) => ({
      ...obj,
      [number]: { days: 0, kilometers: 0, laundryCostCent: 0 },
    }),
    {},
  ),
  dailyKilometers: 0,
  dailyLaundryCostCent: 0,
  CVFiscaux: 3,
});
const getDefaultState = (): KilometersState => ({
  years: {} as Kilometers["years"],
});

interface KilometersPanelProps {
  yearSelected: number;
}
const KilometersPanel = (props: KilometersPanelProps) => {
  const { data: oldKilometers } = trpc.kilometer.get.useQuery();
  const [kilometersState, setKilometersState] =
    React.useState<KilometersState>(getDefaultState());
  const [isDirty, setIsDirty] = React.useState(false);
  useEffect(() => {
    if (!props.yearSelected) {
      return;
    }
    if (kilometersState.years[props.yearSelected]) {
      return;
    }
    setKilometersState((state) => ({
      ...state,
      years: {
        ...state.years,
        [props.yearSelected]: getDefaultYear(),
      },
    }));
  }, [props.yearSelected]);
  useEffect(() => {
    if (!oldKilometers) {
      return;
    }
    setKilometersState(oldKilometers);
  }, [JSON.stringify(oldKilometers)]);
  const onSetKilometers = (month: number, kilometers: number) => {
    if (!props.yearSelected) {
      throw new Error("props.yearSelected is null in onSetKilometers");
    }
    setIsDirty(true);
    setKilometersState((state) => ({
      ...state,
      years: {
        ...state.years,
        [props.yearSelected]: {
          ...state.years[props.yearSelected],
          months: {
            ...state.years[props.yearSelected].months,
            [month]: {
              ...state.years[props.yearSelected]["months"][month],
              kilometers,
            },
          },
        },
      },
    }));
  };
  const onSetWorkedDays = (month: number, days: number) => {
    if (!props.yearSelected) {
      throw new Error("yearSelected is null in onSetKilometers");
    }
    setIsDirty(true);
    setKilometersState((state) => ({
      ...state,
      years: {
        ...state.years,
        [props.yearSelected]: {
          ...state.years[props.yearSelected],
          months: {
            ...state.years[props.yearSelected].months,
            [month]: {
              ...state.years[props.yearSelected]["months"][month],
              days,
            },
          },
        },
      },
    }));
    onRecalcKilometers();
    onRecalcLaundryCost();
  };
  const onSetDailyKilometers = (dailyKilometers: number) => {
    if (!props.yearSelected) {
      throw new Error("props.yearSelected is null in onSetKilometers");
    }
    setIsDirty(true);
    setKilometersState((state) => ({
      ...state,
      years: {
        ...state.years,
        [props.yearSelected]: {
          ...state.years[props.yearSelected],
          dailyKilometers,
        },
      },
    }));
    onRecalcKilometers();
  };
  const onRecalcKilometers = () => {
    if (!props.yearSelected) {
      throw new Error("yearSelected is null in onSetKilometers");
    }
    setIsDirty(true);
    setKilometersState((state) => ({
      ...state,
      years: {
        ...state.years,
        [props.yearSelected]: {
          ...state.years[props.yearSelected],
          months: Object.entries(state.years[props.yearSelected].months).reduce(
            (obj, [month, monthObj]) => ({
              ...obj,
              [month]: {
                ...monthObj,
                kilometers:
                  monthObj.days *
                  state.years[props.yearSelected].dailyKilometers,
              },
            }),
            {},
          ),
        },
      },
    }));
  };
  const onSetCVFiscaux = (CVFiscaux: number) => {
    if (!props.yearSelected) {
      throw new Error("props.yearSelected is null in onSetKilometers");
    }
    setIsDirty(true);
    setKilometersState((state) => ({
      ...state,
      years: {
        ...state.years,
        [props.yearSelected]: {
          ...state.years[props.yearSelected],
          CVFiscaux,
        },
      },
    }));
  };
  const onSetDailyLaundryCost = (dailyLaundryCostCent: number) => {
    if (!props.yearSelected) {
      throw new Error("yearSelected is null in onSetKilometers");
    }
    setIsDirty(true);
    setKilometersState((state) => ({
      ...state,
      years: {
        ...state.years,
        [props.yearSelected]: {
          ...state.years[props.yearSelected],
          dailyLaundryCostCent,
        },
      },
    }));
    onRecalcLaundryCost();
  };
  const onRecalcLaundryCost = () => {
    if (!props.yearSelected) {
      throw new Error("props.yearSelected is null in onSetKilometers");
    }
    setIsDirty(true);
    setKilometersState((state) => ({
      ...state,
      years: {
        ...state.years,
        [props.yearSelected]: {
          ...state.years[props.yearSelected],
          months: Object.entries(state.years[props.yearSelected].months).reduce(
            (obj, [month, monthObj]) => ({
              ...obj,
              [month]: {
                ...monthObj,
                laundryCostCent:
                  monthObj.days *
                  state.years[props.yearSelected].dailyLaundryCostCent,
              },
            }),
            {},
          ),
        },
      },
    }));
  };
  const {
    successMessage,
    errorMessage,
    isSuccess,
    isError,
    onDismissError,
    onIsSuccess,
    onIsError,
  } = useAlert();

  const commitKilometersMutation = trpc.kilometer.commit.useMutation({
    onSuccess: () => {
      onIsSuccess("Enregistré avec succès");
      setIsDirty(false);
    },
    onError: () => {
      onIsError("Erreur lors de l'enregistrement");
    },
  });
  const onCommit = async () => {
    commitKilometersMutation.mutate(kilometersState);
  };

  const totalKilometers =
    props.yearSelected && kilometersState.years[props.yearSelected]
      ? Object.values(kilometersState.years[props.yearSelected].months)
          .map((o) => o.kilometers)
          .reduce((acc, value) => acc + value, 0)
      : 0;
  const totalWorkedDays =
    props.yearSelected && kilometersState.years[props.yearSelected]
      ? Object.values(kilometersState.years[props.yearSelected].months)
          .map((o) => o.days)
          .reduce((acc, value) => acc + value, 0)
      : 0;
  const totalLaundryCostCent =
    props.yearSelected && kilometersState.years[props.yearSelected]
      ? Object.values(kilometersState.years[props.yearSelected].months)
          .map((o) => o.laundryCostCent)
          .reduce((acc, value) => acc + value, 0)
      : 0;
  return (
    <div className="card mt-2">
      <div className="card-header">
        Kilomètres et blanchissage pour l'année {props.yearSelected}
      </div>
      <div className="card-body">
        <div className="row">
          <div className="col-md-2">
            <label htmlFor="kilomètresQuotidiens">
              Kilomètres Quotidiens (facultatif)
            </label>
            <IntegerInput
              id="kilomètresQuotidiens"
              value={
                props.yearSelected
                  ? kilometersState.years[props.yearSelected]?.dailyKilometers
                  : 0
              }
              onValueChange={(value) => onSetDailyKilometers(value || 0)}
            />
          </div>
          <div className="col-md-2">
            <label htmlFor="blanchissageQuotidien">
              Coût quotidien blanchissage
            </label>
            <FloatInput
              id="blanchissageQuotidien"
              valueCent={
                props.yearSelected
                  ? kilometersState.years[props.yearSelected]
                      ?.dailyLaundryCostCent
                  : 0
              }
              onValueCentChange={(value) => onSetDailyLaundryCost(value || 0)}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-2">
            <label htmlFor="cvFiscauxInput">CV Fiscaux</label>
            <IntegerInput
              id="cvFiscauxInput"
              value={
                props.yearSelected
                  ? kilometersState.years[props.yearSelected]?.CVFiscaux
                  : 3
              }
              onValueChange={(value) => onSetCVFiscaux(value || 3)}
            />
          </div>
        </div>
        <div className="col-md-2">
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Mois</th>
                <th scope="col">Jours travaillés</th>
                <th scope="col">Kilomètres parcourus</th>
                <th scope="col">Coût blanchissage</th>
              </tr>
            </thead>
            <tbody>
              {props.yearSelected && kilometersState.years[props.yearSelected]
                ? Object.entries(
                    kilometersState.years[props.yearSelected].months,
                  ).map(([month, obj]) => (
                    <tr key={month}>
                      <td className="align-middle">
                        {formaters.capitaliseEveryFirstLetter(
                          DateTime.fromObject({ month: parseInt(month) + 1 })
                            .setLocale("fr")
                            .toFormat("MMMM"),
                        )}
                      </td>
                      <td>
                        <IntegerInput
                          value={obj.days}
                          onValueChange={(value) =>
                            onSetWorkedDays(parseInt(month), value || 0)
                          }
                        />
                      </td>
                      <td>
                        <IntegerInput
                          value={obj.kilometers}
                          onValueChange={(value) =>
                            onSetKilometers(parseInt(month), value || 0)
                          }
                        />
                      </td>
                      <td>
                        <FloatInput
                          valueCent={obj.laundryCostCent || 0}
                          disabled={true}
                        />
                      </td>
                    </tr>
                  ))
                : null}
              <tr>
                <td className="align-middle">Total</td>
                <td className="align-middle">{totalWorkedDays + " jours"}</td>
                <td className="align-middle">{totalKilometers + " km"}</td>
                <td>
                  {integerCentToFrenchFinanString(totalLaundryCostCent) + " €"}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="row">
          <div className="col-md-2">
            <button
              className="btn btn-primary"
              disabled={!isDirty}
              onClick={onCommit}
            >
              Enregistrer
            </button>
          </div>
        </div>
      </div>
      <SuccessErrorComp
        showAlertSuccess={isSuccess}
        showAlertError={isError}
        successMessage={successMessage}
        errorMessage={errorMessage}
        onDismissError={onDismissError}
      />
    </div>
  );
};

export default KilometersPanel;
