import { ImmobilisationState } from "../useImmobilisation";
import { formaters } from "vincent-utils";
import { Button } from "react-bootstrap";
import { ArrowDownSquare } from "react-bootstrap-icons";
import FloatInput from "../../utils/components/FloatInput.tsx";
import ValidatedInput from "../../utils/components/ValidatedInput.tsx";

interface AmmortissementLinesProps {
  lines: ImmobilisationState["amortissementLines"];
  onModifyAmortissementAtLine: (valueCent: number, index: number) => void;
  onRecalcFromLine: (index: number) => void;
  startOfDirtyLines: number | null;
}

const AmmortissementLines = (props: AmmortissementLinesProps) => {
  return (
    <div className="card">
      <div className="card-header">
        <h4 className="card-title pt-2">Amortissements</h4>
      </div>
      <div className="card-body">
        <form>
          <table>
            <thead>
              <tr>
                <th></th>
                <th>Période</th>
                <th>Ammortissement précédent</th>
                <th>Ammortissement de la période</th>
                <th>Ammortissement cumulé</th>
                <th>Reste à amortir</th>
              </tr>
            </thead>
            <tbody>
              {props.lines.map((line, idx) => (
                <tr key={line._id}>
                  <td>
                    <Button
                      disabled={
                        props.startOfDirtyLines !== null &&
                        idx < props.startOfDirtyLines
                      }
                      variant={
                        props.startOfDirtyLines !== null &&
                        idx >= props.startOfDirtyLines
                          ? "danger"
                          : "light"
                      }
                      onClick={() => props.onRecalcFromLine(idx)}
                    >
                      <ArrowDownSquare />
                    </Button>
                  </td>
                  <td>
                    <ValidatedInput
                      isValid={true}
                      invalidMessage={""}
                      value={
                        "Du " +
                        formaters.formatDateAsDDN(line.yearStartDate) +
                        " au 31/12/" +
                        line.yearStartDate.getFullYear()
                      }
                      disabled={true}
                    />
                  </td>
                  <td>
                    <FloatInput
                      valueCent={line.amortissementPrécédentCent}
                      disabled={true}
                    />
                  </td>
                  <td>
                    <FloatInput
                      valueCent={line.amortissementCent}
                      onValueCentChange={valueCent =>
                        props.onModifyAmortissementAtLine(valueCent || 0, idx)
                      }
                    />
                  </td>
                  <td>
                    <FloatInput
                      valueCent={line.amortissementCumuléCent}
                      disabled={true}
                    />
                  </td>
                  <td>
                    <FloatInput
                      valueCent={line.valeurRésiduelleCent}
                      disabled={true}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </form>
      </div>
    </div>
  );
};

export default AmmortissementLines;
