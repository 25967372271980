//comptes est le plan comptable

import {planComptable} from "compta-shared";

export default (comptes:{[category: string]:{[compteNum: number]: string}}, search:string) => {
  if (!search) {
    return [];
  }
  const entries = Object.entries(
    Object.values(comptes).reduce((result, obj) => ({ ...result, ...obj }))
  );
  return entries.filter(arr => arr[1].match(new RegExp(search, "i")));
};
export const filterCompte = (search: string) => {
  if (!search) {
    return [] as string[];
  }
  const entries = Object.entries(
    Object.values(planComptable).reduce((result, obj) => ({
      ...result,
      ...obj
    }))
  );
  return entries
    .filter(arr => arr[1].match(new RegExp(search, "i")))
    .map(arr => arr.join(" ")) as string[];
};
