import _ from "underts";

import FactureComp from "./FactureComp";
import YearChooser from "./YearChooser";
import type {Facture2} from "compta-shared";

interface AllFacturesCompProps {
  yearFactures: Facture2[];
  yearSelected: number;
  yearChoices: number[];
  onYearSelected: (year: number) => void;
  onFactureClicked: (factureId: string) => void;
}

const AllFacturesComp = (props: AllFacturesCompProps) => {
  const allFactures = props.yearFactures;
  const facturesComp = _.sortBy([...allFactures],(f: Facture2) => "date" in f && f.date ? f.date.getTime(): 0)
    .map(facture => (
      <li className="list-group-item" key={"factureLine" + facture._id}>
        <FactureComp
          facture={facture}
          onFactureClicked={() => props.onFactureClicked(facture._id)}
        />
      </li>
    ));


  return (
    <div>
      <YearChooser
        yearChoices={props.yearChoices}
        yearSelected={props.yearSelected}
        onYearSelected={props.onYearSelected}
      />
      <div className="row">
        <ul className="list-group">{facturesComp}</ul>
      </div>
    </div>
  );
};

export default AllFacturesComp;
