import { useEffect, useState } from "react";
import EcheancierLine from "./EcheancierLine";
import { DateTime } from "luxon";
import { formaters } from "vincent-utils";
import { BanqueLine2, Loan } from "compta-shared";
import FloatInput from "../utils/components/FloatInput.tsx";
import IntegerInput from "../utils/components/IntegerInput.tsx";
import DateInput from "../utils/components/DateInput.tsx";
import { echeancierBuilder } from "../utils/transforms/echeancierBuilder.ts";
import { trpc } from "../main/components/ProviderContainer.tsx";

interface EcheancierPanelProps {
  loan: Loan | null;
  banqueLines: BanqueLine2[];
  isAdding: boolean;
  onCancel: () => void;
}

const getInitialState = (loan: Loan | null) =>
  loan || {
    name: "crédit voiture",
    principalCent: 100000,
    annualRateCent: 100,
    months: 12,
    payments: echeancierBuilder(100000, 100, 12, 100, 0, 0, new Date()),
    monthlyBasePaymentCent: 100,
    monthlyAssuranceCent: 0,
    startDate: new Date(),
    assuranceAnnualRateCent: 0,
    owner: "", //sera rajouté par le serveur
  };

const useLoan = (existingLoan: Loan | null) => {
  const [loan, setLoan] = useState<Loan>(getInitialState(existingLoan));
  const [isModified, setModified] = useState(false); // quoi que ce soit a été modifié et nécessite donc une sauvegarde
  useEffect(() => {
    setLoan(getInitialState(existingLoan));
  }, [JSON.stringify(existingLoan)]);
  const [dirty, _setDirty] = useState(false);
  const setDirty = (value: boolean) => {
    _setDirty(value);
    setModified(true);
  };

  console.log("loan", loan);
  const [baseParamsChanged, _setBaseParamsChanged] = useState(false);
  const setBaseParamsChanged = (value: boolean) => {
    _setBaseParamsChanged(value);
    setModified(true);
  };
  const calculatePayments = () => {
    setLoan((loan) => ({
      ...loan,
      payments: echeancierBuilder(
        loan.principalCent,
        loan.annualRateCent,
        loan.months,
        loan.monthlyBasePaymentCent,
        loan.monthlyAssuranceCent || 0,
        loan.assuranceAnnualRateCent || 0,
        loan.startDate
      ),
    }));
    setBaseParamsChanged(false);
  };
  const setName = (name: string) => {
    setLoan((loan) => ({ ...loan, name }));
    setModified(true);
  };
  const setPrincipalCent = (value: number | null) => {
    setLoan((prev) => ({ ...prev, principalCent: value || 0 }));
    setBaseParamsChanged(true);
  };
  const setAnnualRateCent = (value: number | null) => {
    setLoan((prev) => ({ ...prev, annualRateCent: value || 0 }));
    setBaseParamsChanged(true);
  };
  const setMonths = (value: number) => {
    setLoan((prev) => ({ ...prev, months: value }));
    setBaseParamsChanged(true);
  };
  const setMonthlyBasePaymentCent = (value: number | null) => {
    setLoan((prev) => ({ ...prev, monthlyBasePaymentCent: value || 0 }));
    setBaseParamsChanged(true);
  };
  const setMonthlyAssuranceCent = (value: number | null) => {
    setLoan((prev) => ({
      ...prev,
      monthlyAssuranceCent: value || 0,
      assuranceAnnualRateCent: 0,
    }));
    setBaseParamsChanged(true);
  };
  const setAssuranceAnnualRateCent = (value: number | null) => {
    setLoan((prev) => ({
      ...prev,
      assuranceAnnualRateCent: value || 0,
      monthlyAssuranceCent: 0,
    }));
    setBaseParamsChanged(true);
  };
  const setStartDate = (value: Date | null) => {
    setLoan((prev) => ({
      ...prev,
      startDate: value || formaters.filterDateTimeForPureDate(new Date()),
    }));
    setBaseParamsChanged(true);
  };
  const recalcLineNumbers = () => {
    setLoan((prev) => ({
      ...prev,
      payments: prev.payments.map((p, i) => ({ ...p, number: i + 1 })),
    }));
  };
  const recalcLineDates = () => {
    setLoan((prev) => ({
      ...prev,
      payments: prev.payments.map((p, i) => ({
        ...p,
        date: DateTime.fromJSDate(prev.startDate)
          .plus({ months: i })
          .toJSDate(),
      })),
    }));
  };
  const addLineAtIndex = (index: number) => {
    setLoan((prev) => {
      return {
        ...prev,
        payments: [
          ...prev.payments.slice(0, index),
          {
            date: new Date(),
            principalCent: 100,
            interestCent: 100,
            totalCent: 100,
            remainingPrincipalCent: 100,
            assuranceCent: 0,
            monthlyPaymentCent: 100,
            number: index + 1,
          },
          ...prev.payments.slice(index),
        ],
      };
    });
    recalcLineNumbers();
    recalcLineDates();
    setDirty(true);
  };
  const removeLineAtIndex = (index: number) => {
    setLoan((prev) => {
      return {
        ...prev,
        payments: [
          ...prev.payments.slice(0, index),
          ...prev.payments.slice(index + 1),
        ],
      };
    });
    recalcLineNumbers();
    recalcLineDates();
    setDirty(true);
  };
  const setLinePrincipalCent = (index: number, value: number) => {
    setLoan((prev) => {
      const line = prev.payments[index];
      const prevLine = index !== 0 ? prev.payments[index - 1] : null;
      const newRemainingPrincipalCent = prevLine
        ? prevLine.remainingPrincipalCent - value
        : prev.principalCent - value;
      const monthlyPaymentCent = value + line.assuranceCent + line.interestCent;
      return {
        ...prev,
        payments: [
          ...prev.payments.slice(0, index),
          {
            ...line,
            principalCent: value,
            remainingPrincipalCent: newRemainingPrincipalCent,
            monthlyPaymentCent,
          },
          ...prev.payments.slice(index + 1),
        ],
      };
    });
    setDirty(true);
  };
  const setLineInterestCent = (index: number, value: number) => {
    setLoan((prev) => {
      const line = prev.payments[index];
      const monthlyPaymentCent =
        value + line.assuranceCent + line.principalCent;
      return {
        ...prev,
        payments: [
          ...prev.payments.slice(0, index),
          { ...line, interestCent: value, monthlyPaymentCent },
          ...prev.payments.slice(index + 1),
        ],
      };
    });
    setDirty(true);
  };
  const setLineAssuranceCent = (index: number, value: number) => {
    setLoan((prev) => {
      const line = prev.payments[index];
      const monthlyPaymentCent = value + line.interestCent + line.principalCent;
      return {
        ...prev,
        payments: [
          ...prev.payments.slice(0, index),
          { ...line, assuranceCent: value, monthlyPaymentCent },
          ...prev.payments.slice(index + 1),
        ],
      };
    });
    setDirty(true);
  };
  const recalcFromLine = (index: number) => {
    setLoan((prev) => {
      const line = prev.payments[index];
      const prevLine = index !== 0 ? prev.payments[index - 1] : null;
      if (!prevLine) {
        return prev;
      }
      const startNumber = line.number;
      const echeances = echeancierBuilder(
        prevLine.remainingPrincipalCent,
        prev.annualRateCent,
        prev.months - prevLine.number,
        prev.monthlyBasePaymentCent,
        prev.monthlyAssuranceCent || 0,
        prev.assuranceAnnualRateCent || 0,
        line.date,
        startNumber
      );

      return {
        ...prev,
        payments: [...prev.payments.slice(0, index), ...echeances],
      };
    });
    setDirty(false);
  };
  const utils = trpc.useContext();
  const saveLoanMutation = trpc.loan.commit.useMutation({
    onSuccess: () => {
      utils.loan.invalidate();
    },
  });
  const saveLoan = async () => {
    setModified(false);
    saveLoanMutation.mutate(loan);
  };
  return {
    loan,
    setName,
    calculatePayments,
    setPrincipalCent,
    setAnnualRateCent,
    setMonths,
    setMonthlyBasePaymentCent,
    setMonthlyAssuranceCent,
    setAssuranceAnnualRateCent,
    setStartDate,
    baseParamsChanged,
    addLineAtIndex,
    removeLineAtIndex,
    setLinePrincipalCent,
    setLineInterestCent,
    setLineAssuranceCent,
    dirty,
    isModified,
    recalcFromLine,
    saveLoan,
  };
};

const EcheancierPanel = (props: EcheancierPanelProps) => {
  const {
    loan,
    setName,
    setPrincipalCent,
    setAnnualRateCent,
    setMonths,
    setMonthlyBasePaymentCent,
    setMonthlyAssuranceCent,
    setAssuranceAnnualRateCent,
    calculatePayments,
    setStartDate,
    baseParamsChanged,
    addLineAtIndex,
    removeLineAtIndex,
    setLinePrincipalCent,
    setLineInterestCent,
    setLineAssuranceCent,
    dirty,
    recalcFromLine,
    isModified,
    saveLoan,
  } = useLoan(props.loan);
  // const handleLoanCommit = async () => {
  //   setModified(false);
  //   const result = await commitLoan(loan);
  // };
  const handleNumberChange = (item: "months", valueOrNull: number | null) => {
    if (item === "months" && valueOrNull !== null) {
      setMonths(valueOrNull);
      return;
    }
  };
  const handleLineChange =
    (index: number) =>
    (
      item: "principalCent" | "interestCent" | "assuranceCent",
      value: number | null
    ) => {
      if (item === "principalCent") {
        setLinePrincipalCent(index, value || 0);
        return;
      }
      if (item === "interestCent") {
        setLineInterestCent(index, value || 0);
        return;
      }
      if (item === "assuranceCent") {
        setLineAssuranceCent(index, value || 0);
        return;
      }
    };
  return (
    <div className="card mt-2">
      <div
        className={
          "card-header " +
          (props.isAdding ? "text-bg-success" : "text-bg-secondary")
        }
      >
        {props.isAdding ? (
          <h4 className="pt-2">Nouveau tableau d'amortissement</h4>
        ) : (
          <h4 className="pt-2">Tableau d'amortissement</h4>
        )}
      </div>
      <div className="card-body">
        <label htmlFor="name">Descriptif</label>
        <input
          id="name"
          type="text"
          value={loan.name}
          onChange={(e) => setName(e.target.value)}
          className="form-control"
        />
        <label htmlFor="capitalInput">Capital emprunté</label>
        <FloatInput
          id="capitalInput"
          onValueCentChange={setPrincipalCent}
          valueCent={loan.principalCent}
        />
        <label htmlFor="annualRate">Taux annuel</label>
        <FloatInput
          id="annualRate"
          onValueCentChange={setAnnualRateCent}
          valueCent={loan.annualRateCent}
        />
        <label htmlFor="months">Nombre de mois</label>
        <IntegerInput
          id="months"
          onValueChange={(valueOrNull) =>
            handleNumberChange("months", valueOrNull)
          }
          value={loan.months}
          forbidZero
        />
        <label htmlFor="monthlyBasePayment">
          Montant de chaque échéance mensuelle
        </label>
        <FloatInput
          id={"monthlyBasePayment"}
          onValueCentChange={setMonthlyBasePaymentCent}
          valueCent={loan.monthlyBasePaymentCent}
        />
        <label htmlFor="monthlyAssurance">
          Montant de chaque assurance mensuelle
        </label>
        <FloatInput
          id={"monthlyAssurance"}
          onValueCentChange={setMonthlyAssuranceCent}
          valueCent={loan.monthlyAssuranceCent || 0}
        />
        <label htmlFor="assuranceRate">Taux annuel assurance</label>
        <FloatInput
          id="assuranceRate"
          onValueCentChange={setAssuranceAnnualRateCent}
          valueCent={loan.assuranceAnnualRateCent || 0}
        />
        <label htmlFor="startDate">Date de début</label>
        <DateInput
          id="startDate"
          date={loan.startDate}
          onDateChange={setStartDate}
        />
        {baseParamsChanged ? (
          <button
            type="button"
            className="btn btn-primary"
            onClick={calculatePayments}
          >
            (Re)Calculer
          </button>
        ) : null}
        <form>
          <table>
            <thead>
              <tr>
                <th></th>
                <th></th>
                <th></th>
                <th>Echéance numéro</th>
                <th>Date</th>
                <th>Montant échéance</th>
                <th>Capital</th>
                <th>Intérêts</th>
                <th>Capital restant dû</th>
                <th>Assurance</th>
                <th>Date ligne banque (rapprochée)</th>
                <th>Intitulé ligne banque (rapprochée)</th>
              </tr>
            </thead>
            <tbody>
              {loan.payments.map((payment, idx) => (
                <EcheancierLine
                  key={payment.number}
                  payment={payment}
                  onChange={handleLineChange(idx)}
                  banqueLine={
                    props.banqueLines.find(
                      (line) => line._id === payment.banqueId
                    ) || null
                  }
                  onAddLine={() => addLineAtIndex(idx)}
                  onRemoveLine={() => removeLineAtIndex(idx)}
                  recalcEnabled={dirty}
                  onRecalcNextLines={() => recalcFromLine(idx)}
                />
              ))}
            </tbody>
          </table>
          {isModified ? (
            <div>
              <button
                type="button"
                className="btn btn-primary"
                onClick={saveLoan}
              >
                Enregistrer
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={props.onCancel}
              >
                Annuler modifications
              </button>
            </div>
          ) : null}
        </form>
      </div>
    </div>
  );
};

export default EcheancierPanel;
