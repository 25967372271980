import RelevesAvailableLine from "./RelevesAvailableLine";
import {trpc} from "../../main/components/ProviderContainer.tsx";

interface RelevesAvailableProps {
  years: number[];
}

const RelevesAvailable = (props: RelevesAvailableProps) => {


  const { isError, isLoading, data } = trpc.banque.getRelevesMonthsAvailable.useQuery({
    yearStart: props.years[0],
    yearEndIncluding: props.years[props.years.length - 1]
    });
  if (!isError && !isLoading && !!data) {
    return (
      <div>
        {Object.entries(data).map(([year, bools]) => (
          <RelevesAvailableLine
            monthesAvailable={bools}
            year={year}
            key={year + "relevesAvailableLineKey"}
          />
        ))}
      </div>
    );
  } else {
    return <div>Loading...</div>;
  }
};

export default RelevesAvailable;
