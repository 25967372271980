import React, { useEffect } from "react";
import { WithPartial } from "underts";
import { Madelin } from "compta-shared";
import { trpc } from "../main/components/ProviderContainer.tsx";
import useAlert from "../utils/hooks/useAlert.ts";
import FloatInput from "../utils/components/FloatInput.tsx";
import SuccessErrorComp from "../utils/components/SuccessErrorComp.tsx";

export type MadelinState = WithPartial<Madelin, "_id" | "owner">;

interface MadelinPanelProps {
  yearSelected: number;
}
const MadelinPanel = (props: MadelinPanelProps) => {
  const yearSelected = props.yearSelected;
  const { data: oldMadelin } = trpc.madelin.get.useQuery();
  const [madelin, setMadelin] = React.useState<MadelinState>({
    years: {} as Madelin["years"],
  });
  useEffect(() => {
    if (oldMadelin) {
      setMadelin(oldMadelin);
      setIsDirty(false);
    }
  }, [JSON.stringify(oldMadelin)]);
  const [isDirty, setIsDirty] = React.useState(false);
  const {
    successMessage,
    errorMessage,
    isSuccess,
    isError,
    onDismissError,
    onIsSuccess,
    onIsError,
  } = useAlert();

  const madelinCommitMutation = trpc.madelin.commit.useMutation({
    onSuccess: () => {
      setIsDirty(false);
      onIsSuccess("Enregistré avec succès");
    },
    onError: () => {
      onIsError("Erreur lors de l'enregistrement");
    },
  });
  const handleCommit = async () => {
    madelinCommitMutation.mutate(madelin);
  };
  useEffect(() => {
    if (yearSelected) {
      if (!madelin.years[yearSelected]) {
        setMadelin((state) => ({
          ...state,
          years: {
            ...state.years,
            [yearSelected]: {
              montantCent: 0,
            },
          },
        }));
        setIsDirty(true);
      }
    }
  }, [JSON.stringify(yearSelected)]);
  const onSetMontant = (montantCent: number | null) => {
    if (!yearSelected) {
      throw new Error("yearSelected is null");
    }
    setMadelin((state) => ({
      ...state,
      years: {
        ...state.years,
        [yearSelected]: {
          ...state.years[yearSelected],
          montantCent: montantCent || 0,
        },
      },
    }));
    setIsDirty(true);
  };
  return (
    <>
      {yearSelected && madelin.years[yearSelected] ? (
        <div>
          <div className="card mt-2">
            <div className="card-header">Madelin</div>
            <div className="row">
              <div className="col-md-2">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label"
                >
                  Montant déductible Madelin pour l'année {yearSelected}
                </label>
                <FloatInput
                  valueCent={madelin.years[yearSelected].montantCent}
                  onValueCentChange={onSetMontant}
                />
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-md-2">
                <button
                  type="button"
                  className="btn btn-primary"
                  disabled={!isDirty}
                  onClick={handleCommit}
                >
                  Enregistrer
                </button>
              </div>
            </div>
          </div>
          <SuccessErrorComp
            showAlertSuccess={isSuccess}
            showAlertError={isError}
            successMessage={successMessage}
            errorMessage={errorMessage}
            onDismissError={onDismissError}
          />
        </div>
      ) : null}
    </>
  );
};

export default MadelinPanel;
