import {LogTresoType, ManualComptaLine} from "compta-shared";
import _ from "underts";

const sum = (sequence: { montantCent: number }[]) =>
  sequence.reduce((result, item) => result + item.montantCent, 0);

interface Candidat {
  montantCent: number;
  _id: string;
}

//target est la somme à atteindre
//count est optionnel, indique le nombre de candidats à éventuellement respecter dans la combinaison
const montantCombinaisons = (
  candidats: Candidat[],
  target: number,
  count?: number
) => {
  const results: Candidat[][] = [];

  const getSequence = (
    candidats: Candidat[],
    combinaisons: Candidat[],
    target: number
  ) => {

    if (candidats.length === 0) {
      return;
    }

    const sumCombi = sum(combinaisons);
    const combiLength = combinaisons.length;

    if (count && combiLength === count){
      if (sumCombi === target){
        results.push(combinaisons);
      }
      return;
    }

    // if ((count ? combiLength === count : true) && sumCombi === target) {
    //   results.push(combinaisons);
    //   return;
    // }
    //
    // //pas besoin de chercher plus loin
    // if (combiLength === count) {
    //   return;
    // }



    if (count && combiLength === count - 1 ) {
        const lastCandidat = candidats.find(c => sumCombi + c.montantCent === target);
        if (lastCandidat) {
            results.push(combinaisons.concat(lastCandidat));
        }
        return;
    }

    const filtered = candidats.filter(c => sumCombi + c.montantCent <= target);
    // return _.chain(filtered).map((candidat, idx) => (getSequence(filtered.slice(idx + 1), combinaisons.concat(candidat), target)))
    //   .filter(arr => (!isEmptyArr(arr))).compact().value();
    filtered.forEach((candidat, idx) =>
      getSequence(
        filtered.slice(idx + 1),
        combinaisons.concat(candidat),
        target
      )
    );
  };

  getSequence(candidats, [], target);
  return results;
};

//target est la somme à atteindre
//count est optionnel, indique le nombre de candidats à éventuellement respecter dans la combinaison
const montantCombinaisons2 = (
    candidats: Candidat[],
    target: number,
    included: string[],
    count?: number
) => {
  const results: Candidat[][] = [];
  const includedCandidates = candidats.filter(c => included.includes(c._id));
  const otherCandidates = candidats.filter(c => !included.includes(c._id));

  const getSequence = (
      candidats: Candidat[],
      combinaison: Candidat[],
      target: number
  ) => {

    if (candidats.length === 0) {
      return;
    }

    const sumCombi = sum(combinaison);
    const combiLength = combinaison.length;

    if (count && combiLength === count){
      if (sumCombi === target){
        results.push(combinaison);
      }
      return;
    }

    // if ((count ? combiLength === count : true) && sumCombi === target) {
    //   results.push(combinaison);
    //   return;
    // }
    //
    // //pas besoin de chercher plus loin
    // if (combiLength === count) {
    //   return;
    // }



    if (count && combiLength === count - 1 ) {
      const lastCandidat = candidats.find(c => sumCombi + c.montantCent === target);
      if (lastCandidat) {
        results.push(combinaison.concat(lastCandidat));
      }
      return;
    }

    const filtered = candidats.filter(c => sumCombi + c.montantCent <= target);
    // return _.chain(filtered).map((candidat, idx) => (getSequence(filtered.slice(idx + 1), combinaisons.concat(candidat), target)))
    //   .filter(arr => (!isEmptyArr(arr))).compact().value();
    filtered.forEach((candidat, idx) =>
        getSequence(
            filtered.slice(idx + 1),
            combinaison.concat(candidat),
            target
        )
    );
  };

  getSequence(otherCandidates, [...includedCandidates], target);
  return results;
};


const isNested = <U extends any | any[]>(arr: U[]) =>
  Array.isArray(arr) && arr.some(i => Array.isArray(i));

const isDoubleNested = <U extends any[] | any[][]>(arr: U[]) =>
  Array.isArray(arr) && arr.some(isNested);

const isTripleNested = <U extends any[][] | any[][][]>(arr: U[]) =>
  Array.isArray(arr) && arr.some(isDoubleNested);

const candidatesFlagger = (
  candidates: (LogTresoType | ManualComptaLine)[],
  combinaisons: Candidat[][]
) => {
  const candidatesIdByCombinaisons = combinaisons.reduce(
    (result, combinaison, idx) => {
      return {
        ...result,
        ...combinaison.reduce((res, combi) => {
          return {
            ...res,
            [idx]: res[idx] ? [...res[idx], combi._id] : [combi._id]
          };
        }, {} as { [combinaisonNumber: number]: string[] })
      };
    },
    {} as { [combinaisonNumber: number]: string[] }
  );

  return candidates.map(candidate => {
    const found: number[] = [];
    Object.entries(candidatesIdByCombinaisons).forEach(
      ([combinaisonNumber, arr]) => {
        if (arr.indexOf(candidate._id) > -1) {
          found.push(parseInt(combinaisonNumber));
        }
      }
    );
    return { ...candidate, proposed: [...found] };
  });
};



const combinaisonToIds = (combinaison: Candidat[]) => {
    return combinaison.map(c => c._id);
}

const combinaisonsToUniqueIds = (combinaisons: Candidat[][]) => {
    return _.uniq(combinaisons.map(combinaisonToIds).flat(1));
}

const test = { isTripleNested, isDoubleNested, isNested };

export { test, candidatesFlagger, montantCombinaisons, combinaisonsToUniqueIds, montantCombinaisons2 };
