
import BanqueLineComp from "./BanqueLineComp";
import {BanqueLine2} from "compta-shared";

interface BanqueChooserProps {
  banqueLines: BanqueLine2[];
  selectBanqueLine: (_id: string) => void;
  banqueLineSelected: BanqueLine2 | null;
  showVentilations: (_id: string) => void;
}
const BanqueChooser = ({
  banqueLines,
  selectBanqueLine,
  banqueLineSelected,
  showVentilations
}: BanqueChooserProps) => {
  //const onRowClick = index => () => (selectBanqueLine(index));
  const lineComps = banqueLines.map((line, idx) => (
    <BanqueLineComp
      banqueLine={line}
      onClick={() => selectBanqueLine(line._id)}
      selected={!!banqueLineSelected && banqueLineSelected._id === line._id}
      onNotHonorairesClick={showVentilations}
      key={"bangueLine" + idx}
    />
  ));

  return <ul className="list-group">{lineComps}</ul>;
};

export default BanqueChooser;
