import { DateTime } from "luxon";

const SlashDateFrenchDisplay = ({ date }: { date: Date }) => (
  <>
    {DateTime.fromJSDate(date)
      .setLocale("fr")
      .toLocaleString()}
  </>
);

export default SlashDateFrenchDisplay;
