import FactureRèglementInputLine from "./FactureRèglementInputLine";
import { FactureState, useFacture } from "../hooks/useFacture";

type FactureRèglementsCompProps = ReturnType<
  typeof useFacture
>["règlementsProps"] & {
  onOpenVentilations: (idx: number) => void;
  onDeleteRèglementLine: (idx: number) => void;
  règlements: FactureState["règlements"];
  linesIndexWithVentilations: { [key: number]: boolean };
};

const FactureRèglementsComp = (props: FactureRèglementsCompProps) => {
  const {
    règlements,
    onSetRèglementItemValueAtIndex,
    onConditionnalyAddRèglementLine,
    onOpenVentilations
  } = props;

  return (
    <div className="card mt-3">
      <div className="card-header">
        <span className="fs-4 pt-2">Règlements multiples </span>
      </div>
      <div className="card-body">
        <form>
          {règlements.map((regl, idx) => (
            <FactureRèglementInputLine
              {...regl}
              onChange={(item, value) =>
                onSetRèglementItemValueAtIndex(idx, item, value)
              }
              onVentilationClick={() => onOpenVentilations(idx)}
              onDeleteRèglement={() => props.onDeleteRèglementLine(idx)}
              key={regl.uuid}
              onLineType={() => onConditionnalyAddRèglementLine(idx)}
              hasVentilations={!!props.linesIndexWithVentilations[idx]}
            />
          ))}
        </form>
      </div>
    </div>
  );
};

export default FactureRèglementsComp;
