import React from "react";
import fetcher from "../../utils/fetcher.ts";
import { Redirect, Route, Switch, useLocation } from "wouter";

const prefix = "comptaRest";

const getBalance = async (year: number) => {
  const { blob, fileName } = await fetcher(
    `${prefix}/getBalanceFile`,
    { year },
    {},
    true,
  );
  const url = URL.createObjectURL(blob);
  // window.open(URL.createObjectURL(blob));
  const link = document.createElement("a");
  link.href = url;
  link.download = fileName;

  document.body.appendChild(link);

  link.click();

  link.parentNode!.removeChild(link);
};

interface BalancePanelProps {
  yearSelected: number | null;
}

const BalancePanel = (props: BalancePanelProps) => {
  const [isFetching, setIsFetching] = React.useState(false);
  const [, setLocation] = useLocation();
  React.useEffect(() => {
    setLocation(`/${props.yearSelected}`);
  }, [props.yearSelected]);

  const handleDownload = () => {
    if (isFetching) return;
    if (!props.yearSelected) return;
    setIsFetching(true);
    getBalance(props.yearSelected).then(() => {
      // console.log("res from balanceDownload", res);
      //window.open(res);
      setIsFetching(false);
    });
  };
  return (
    <Switch>
      <Route path="/:year">
        <div className="card">
          <div className="card-header">
            <h3 className="card-title mt-1">Balance</h3>
          </div>
          <div className="card-body">
            <div className="row">
              <span className="align-middle">
                Télécharger balance pour l'année {props.yearSelected}
              </span>
              <div className="col-2">
                <button
                  className="btn btn-primary"
                  onClick={handleDownload}
                  disabled={isFetching || !props.yearSelected}
                >
                  Télécharger
                </button>
              </div>
            </div>
          </div>
        </div>
      </Route>
      <Redirect to={`/${props.yearSelected}`} />
    </Switch>
  );
};

export default BalancePanel;
