import { createRoot } from "react-dom/client";
import "./index.css";
import "bootstrap/dist/css/bootstrap.css";
import Root from "./Root";

const container = document.getElementById("renderRoot");
const root = createRoot(container!);
root.render(<Root />);

window.addEventListener("popstate", () => {
  console.log("popstate", window.location.pathname);
});
