import  { useEffect, useState } from "react";

interface ValidatedInputProps {
  invalidMessage: string;
  isValid: boolean;
  value: string | null;
  onChange: (value: string) => void;
  disabled?: boolean;
  placeholder?: string;
  onBlur?: () => void;
}

interface DisabledValidatedInputProps {
  value: string | null;
  disabled: true;
}

const ValidatedInput = (
  props: ValidatedInputProps | DisabledValidatedInputProps
) => {
  const [isTouched, setIsTouched] = useState(false);

  //quand on remet le champs à zéro, ce n'est plus touched
  useEffect(() => {
    if (!props.value) setIsTouched(false);
  }, [props.value]);
  const handleOnBlur = () => {
    setIsTouched(true);
    if ("onBlur" in props && props.onBlur) props.onBlur();
  };
  return (
    <div onBlur={handleOnBlur}>
      <input
        type="text"
        className={
          "form-control" +
          (isTouched
            ? "isValid" in props && props.isValid
              ? " is-valid"
              : " is-invalid"
            : "")
        }
        placeholder={("placeholder" in props && props.placeholder) || ""}
        onChange={event =>
          "onChange" in props && props.onChange(event.target.value)
        }
        value={props.value || ""}
        disabled={props.disabled}
      />
      {isTouched && "isValid" in props && !props.isValid && (
        <div className="invalid-feedback d-block">{props.invalidMessage}</div>
      )}
    </div>
  );
};

export default ValidatedInput;
