import Fuse from "fuse.js";
import _ from "underts";
import {BanqueLine2} from "compta-shared";

const getSameValues = (value: number, lines: BanqueLine2[]) => {
  return lines.filter(line=> line.montantCent === value);
};

const getSimilarLibelles = (libelle: string, lines: BanqueLine2[]) => {
  const options = {
    tokenize: true,
    matchAllTokens: true,
    findAllMatches: true,
    keys: ["libelle"],
    threshold: 0.5,
    location: 0,
    distance: 100
  };
  const fuse = new Fuse(lines, options);
  return fuse.search(libelle).map(({ item }) => item);
};

const getSimilarLines = (toMatch: BanqueLine2, lines: BanqueLine2[]) => {
  const withoutInput = lines.filter(l => !_.isEqual(toMatch, l));
  return _.uniq([
    ...getSimilarLibelles(toMatch?.libelle, withoutInput),
    ...getSameValues(
      toMatch.montantCent,
      withoutInput
    )
  ]);
};
const test = { getSameValues, getSimilarLibelles };

export { test, getSimilarLines };
