import React from "react";
import _ from "underts";
import { trpc } from "../main/components/ProviderContainer.tsx";

const LaunchTasks = () => {
  const [hasTasksRunning, setHasTasksRunning] = React.useState(false);
  const currentYear = new Date().getFullYear();
  const previousYears = _.range(5, -1, -1).map((i) => currentYear - i);

  const rebuildGrandLivreMutation = trpc.grandLivre.rebuild.useMutation({
    onSettled: () => {
      setHasTasksRunning(false);
    },
  });
  const rapprocheBordereauxMutation = trpc.rapprochement.bordereaux.useMutation(
    {
      onSettled: () => {
        setHasTasksRunning(false);
      },
    },
  );
  const rapprocheFacturesMutation = trpc.rapprochement.factures.useMutation({
    onSettled: () => {
      setHasTasksRunning(false);
    },
  });
  const handleRebuildGrandLivre = async (year: number) => {
    setHasTasksRunning(true);
    rebuildGrandLivreMutation.mutate(year);
  };
  const handleRapprocheBordereaux = async () => {
    setHasTasksRunning(true);
    rapprocheBordereauxMutation.mutate();
  };
  const handleRapprocheFactures = async () => {
    setHasTasksRunning(true);
    rapprocheFacturesMutation.mutate();
  };
  return (
    <div className="card">
      <div className="card-header">
        <h3 className="card-title">Tâches</h3>
      </div>
      <div className="card-body">
        {previousYears.map((year) => (
          <div className="row" key={"button" + year}>
            <button
              className="btn btn-primary"
              onClick={() => handleRebuildGrandLivre(year)}
              disabled={hasTasksRunning}
            >
              Rebuild Grand Livre {year}
            </button>
          </div>
        ))}
        <div className="row" key={"rapprocheBordereauKey"}>
          <button
            className="btn btn-primary"
            onClick={() => handleRapprocheBordereaux()}
            disabled={hasTasksRunning}
          >
            Rapproche Bordereaux
          </button>
        </div>
        <div className="row" key={"rapprocheFacturesKey"}>
          <button
            className="btn btn-primary"
            onClick={() => handleRapprocheFactures()}
            disabled={hasTasksRunning}
          >
            Rapproche Factures
          </button>
        </div>
      </div>
    </div>
  );
};

export default LaunchTasks;
