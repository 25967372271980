import AllFacturesComp from "../components/AllFacturesComp";
import _ from "underts";
import { trpc } from "../../main/components/ProviderContainer.tsx";
import { Redirect, Route, Switch, useLocation } from "wouter";

interface AllFacturesContainerProps {
  defaultYear: number;
}

const AllFacturesContainer = (props: AllFacturesContainerProps) => {
  const [location, setLocation] = useLocation();
  const yearSelected = parseInt(location.split("/")[1]);
  const handleSelectYear = (year: number) => {
    setLocation("/" + year, { replace: true });
  };
  console.log("location in AllFacturesContainer", location);
  const { data: yearFactures } = trpc.facture.getByYear.useQuery(
    yearSelected as number,
    { enabled: !!yearSelected },
  );
  const currentYear = new Date().getFullYear();
  const previousYears = _.range(5, -1, -1).map((i) => currentYear - i);
  const onFactureClicked = (factureId: string) => {
    setLocation("~/factures/factureInput?factureId=" + factureId, {
      state: "back",
    });
  };
  return (
    <>
      <Switch>
        <Route path="/:year">
          <AllFacturesComp
            yearFactures={yearFactures || []}
            yearSelected={yearSelected}
            onYearSelected={handleSelectYear}
            yearChoices={previousYears}
            onFactureClicked={onFactureClicked}
          />
        </Route>
        <Redirect to={"/" + props.defaultYear} />
      </Switch>
    </>
  );
};

export default AllFacturesContainer;
