//on met à jour la valeur à l'index précisé
//si le total des pourcentages jusqu'à l'index compris est inférieur à 100, on rajoute un pourcentage pour que ça fasse 100 ?
import { financePercentageCent, smartRounder } from "vincent-utils";
import React, { useEffect } from "react";
import { VentilationUpdateValueTypes } from "../../factures/components/VentilationLine";
import _ from "underts";
import { ventilationsValidator } from "../validators/ventilationsValidator";
import { FactureVentilation2 } from "compta-shared";

const existy = <T>(val: T) => val != null;
const autoPercentagesCent = (
  toAdd: number | null,
  index: number,
  percentagesArray: number[],
) => {
  if (toAdd == null || !existy(index) || !Array.isArray(percentagesArray)) {
    return percentagesArray;
  }
  const newArray = percentagesArray
    .slice(0, index)
    .concat(toAdd)
    .concat(percentagesArray.slice(index + 1));
  const sum = newArray
    .slice(0, index + 1)
    .reduce((a, b) => (a != null && b != null ? a + b : 0), 0);
  if (sum != null && sum < 10000) {
    const toDistribute = 10000 - sum;
    const restArraySize = newArray.slice(index + 1).length || 1; //si plus de place, on rajoute une ligne
    const eachBiteAmount = toDistribute / restArraySize;

    return newArray.slice(0, index + 1).concat(
      smartRounder(
        toDistribute,
        _.range(restArraySize).map((_) => eachBiteAmount),
      ),
    );
  }
  return newArray;
};

export interface VentilationsState {
  ventilations: FactureVentilation2[];
  totalCent: number;
}

const processVentilationsTotal = (ventilations: FactureVentilation2[]) => {
  return ventilations.reduce((acc, val) => acc + val.montantCent, 0);
};
const addVentilationValue = <K extends keyof VentilationUpdateValueTypes>(
  state: FactureVentilation2[],
  field: K,
  value: VentilationUpdateValueTypes[K],
  totalCent: number,
  idx: number,
): FactureVentilation2[] => {
  //on efface le champ pourcentage
  if (field === "pourcentageCent" && !value) {
    if (state[idx]?.pourcentageCent === 0) {
      //il n'y avait déjà pas de pourcentage à cet index
      return state;
    }
    return state.map((val, index) => {
      if (index < idx) {
        return val;
      }
      if (index >= idx) {
        return {
          ...val,
          pourcentageCent: 0,
          montantCent: 0,
        };
      }
      return val;
    });
  }
  if (field === "pourcentageCent" && value === 10000) {
    return state.map((val, index) => {
      if (index !== idx) {
        return {
          ...val,
          pourcentageCent: 0,
          montantCent: 0,
        };
      }
      if (index === idx) {
        return {
          ...val,
          pourcentageCent: 10000,
          montantCent: totalCent,
        };
      }
      return val;
    });
  }
  if (field === "pourcentageCent") {
    const percentagesArray = state.map((val) => val.pourcentageCent || 0);
    const newPercentagesArray = autoPercentagesCent(
      value as VentilationUpdateValueTypes["pourcentageCent"],
      idx,
      percentagesArray,
    );
    const values = financePercentageCent(totalCent, newPercentagesArray);
    return newPercentagesArray.map((pourcentageCent, idx) => {
      return {
        ...state[idx],
        pourcentageCent,
        montantCent: values[idx],
        compte: state[idx]?.compte || "",
      };
    });
  }

  if (field === "montantCent") {
    const val = state[idx];
    return [
      ...state.slice(0, idx),
      { ...val, [field]: value },
      ...state.slice(idx + 1),
    ];
  }

  if (field === "compte") {
    const val = state[idx];
    return [
      ...state.slice(0, idx),
      { ...val, [field]: value },
      ...state.slice(idx + 1),
    ];
  }

  throw new Error("field not supported");
};

const getInitialVentilationsState = (
  ventilations: FactureVentilation2[],
  totalCent: number,
) => {
  return {
    ventilations: [
      ...(ventilations.length > 0
        ? ventilations
        : [{ compte: "", montantCent: totalCent, pourcentageCent: 10000 }]),
    ],
    totalCent,
  };
};

const padVentilationsLines = (state: VentilationsState) => {
  const ventilations = state.ventilations;
  const lastLine = ventilations[ventilations.length - 1];
  if (Object.values(lastLine).some((val) => !!val)) {
    return {
      ...state,
      ventilations: [
        ...ventilations,
        { compte: "", pourcentageCent: 0, montantCent: 0 },
      ],
    };
  }
  return state;
};

const onDeleteVentilation = (state: VentilationsState, idx: number) => {
  return {
    ...state,
    ventilations: [
      ...state.ventilations.slice(0, idx),
      ...state.ventilations.slice(idx + 1),
    ],
  };
};

const onUpdateVentilation = <K extends keyof VentilationUpdateValueTypes>(
  state: VentilationsState,
  idx: number,
  field: K,
  value: VentilationUpdateValueTypes[K],
) => {
  const newState = {
    ...state,
    ventilations: addVentilationValue(
      state.ventilations,
      field,
      value,
      state.totalCent,
      idx,
    ),
  };
  return padVentilationsLines(newState);
};

export const useVentilations = (
  oldVentilations: FactureVentilation2[],
  totalCentTarget: number,
) => {
  const [state, setState] = React.useState(
    getInitialVentilationsState(oldVentilations, totalCentTarget),
  );
  console.log("state in useVentilations", state);

  const setVentilationState = (existingState: typeof state) => {
    setState(existingState);
  };
  useEffect(() => {
    setState(getInitialVentilationsState(oldVentilations, totalCentTarget));
  }, [JSON.stringify(oldVentilations), totalCentTarget]);

  const _onDeleteVentilation = (idx: number) => {
    setState((state) => onDeleteVentilation(state, idx));
  };

  const _onDeleteAllVentilations = () => {
    setState((state) => ({ ...state, ventilations: [] }));
  };
  const _onUpdateVentilation = <K extends keyof VentilationUpdateValueTypes>(
    idx: number,
    field: K,
    value: VentilationUpdateValueTypes[K],
  ) => {
    setState((state) => onUpdateVentilation(state, idx, field, value));
  };
  const totalActuelVentilationsCent = processVentilationsTotal(
    state.ventilations,
  );
  const errors = ventilationsValidator(state, false);

  const serializedVentilations = state.ventilations.filter((v) => !!v.compte);

  return {
    ventilationsState: state,
    ventilations: state.ventilations,
    onUpdateVentilation: _onUpdateVentilation,
    onDeleteVentilation: _onDeleteVentilation,
    onDeleteAllVentilations: _onDeleteAllVentilations,
    totalActuelVentilationsCent,
    setVentilationState,
    targetTotalCent: state.totalCent,
    ventilationsErrors: errors,
    serializedVentilations,
  };
};

export {
  onUpdateVentilation,
  onDeleteVentilation,
  getInitialVentilationsState,
  padVentilationsLines,
  processVentilationsTotal,
};
