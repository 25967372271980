interface RelevesAvailableLineProps {
  monthesAvailable: boolean[];
  year: string;
}

const MONTHS_IN_FRENCH = [
  "Janvier",
  "Février",
  "Mars",
  "Avril",
  "Mai",
  "Juin",
  "Juillet",
  "Août",
  "Septembre",
  "Octobre",
  "Novembre",
  "Décembre"
];

const RelevesAvailableLine = (props: RelevesAvailableLineProps) => {
  const { monthesAvailable } = props;
  return (
    <div>
      <span className="badge bg-info">{props.year}</span>
      {MONTHS_IN_FRENCH.map((month, idx) =>
        monthesAvailable[idx] ? (
          <span
            className="badge bg-success"
            key={props.year.toString() + month + "relevesAvailableLinkeYey"}
          >
            {month}
          </span>
        ) : (
          <span
            className="badge bg-danger"
            key={props.year.toString() + month + "relevesAvailableLinkeYey"}
          >
            {month}
          </span>
        )
      )}
    </div>
  );
};

export default RelevesAvailableLine;
