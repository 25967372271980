import { useEffect } from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { createTRPCReact, httpBatchLink, TRPCLink } from "@trpc/react-query";
import superjson from "superjson";
import { useSetAtom } from "jotai";
import { LoggedUserId } from "../state/globalState";
import type { AppRouter } from "compta-trpc-server";
import { observable } from "@trpc/server/observable";
import type { User } from "compta-shared";
import MainContainer from "./MainContainer.tsx";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
export const queryClient = new QueryClient();

const hostDirty = import.meta.env.VITE_DEV_HOST || window.location.host;
const clientBuildNumber = import.meta.env.VITE_BUILD_NUMBER;
const mode = import.meta.env.MODE;
const prefix = mode === "development" ? "http://" : "https://";
const host = hostDirty.replace("http://", "");
const url = `${prefix}${host}/comptaApi`;

export const trpc = createTRPCReact<AppRouter>();

function getBuildNumberFromResponse(response: Response) {
  const headers = response.headers;
  const build = headers.get("x-build-number");
  console.log("build", build);
  return build;
}

const customLink: TRPCLink<AppRouter> = () => {
  return ({ next, op }) => {
    // this is when passing the result to the next link
    // each link needs to return an observable which propagates results
    return observable((observer) => {
      const unsubscribe = next(op).subscribe({
        next(value) {
          const response = value.context?.response as Response | undefined;
          const serverBuildNumber = response
            ? getBuildNumberFromResponse(response)
            : null;
          if (serverBuildNumber && serverBuildNumber !== clientBuildNumber) {
            console.log("force reload");
            console.log("serverBuildNumber", serverBuildNumber);
            console.log("clientBuildNumber", clientBuildNumber);
            location.reload();
          }
          response && getBuildNumberFromResponse(response);
          observer.next(value);
        },
        error(err) {
          observer.error(err);
        },
        complete() {
          observer.complete();
        },
      });
      return unsubscribe;
    });
  };
};

const trpcClient = trpc.createClient({
  links: [
    customLink,
    httpBatchLink({
      url,
      fetch(url, options) {
        return fetch(url, {
          ...options,
          credentials: "include",
        });
      },
      transformer: superjson,
    }),
  ],
});

interface MainContainerProps {
  user: User | null;
}

const ProviderContainer = (props: MainContainerProps) => {
  const setUserId = useSetAtom(LoggedUserId);
  const user = props.user;
  const userId = user?._id;

  useEffect(() => {
    setUserId(userId || "");
  }, [userId]);
  return (
    <trpc.Provider client={trpcClient} queryClient={queryClient}>
      <div>
        <MainContainer />
      </div>
    </trpc.Provider>
  );
};

export default (props: MainContainerProps) => (
  <QueryClientProvider client={queryClient}>
    <ProviderContainer {...props} />
    <ReactQueryDevtools initialIsOpen={false} />
  </QueryClientProvider>
);
