import { formaters } from "vincent-utils";
import { MainMenuNamesType } from "../../main/components/MainContainer.tsx";
import { trpc } from "../../main/components/ProviderContainer.tsx";
import { Link } from "wouter";
interface MainNavBarProps {
  navNames: readonly MainMenuNamesType[];
  navSelected: MainMenuNamesType | null;
  selectNav: (nav: MainMenuNamesType) => void;
  year: number;
  onSetYear: (year: number) => void;
}

const MainNavBar = ({
  selectNav,
  navSelected,
  navNames,
  year,
  onSetYear,
}: MainNavBarProps) => {
  const { data: settings } = trpc.setting.get.useQuery();
  console.log("settings", settings);
  const yearChoices =
    (settings &&
      Object.keys(settings.years).map((year) => parseInt(year, 10))) ||
    [];
  return (
    <>
      <nav className="navbar navbar-expand-lg bg-body-tertiary">
        <div className="container-fluid">
          <Link href={"~/"}>Compta</Link>
          <div className="collapse navbar-collapse" id="basic-navbar-nav">
            <ul className="navbar-nav me-auto">
              {navNames.map((navName) => (
                <li
                  className={"nav-item "}
                  onClick={() => selectNav(navName)}
                  key={navName + "key"}
                  style={{ cursor: "pointer" }}
                >
                  <a
                    className={
                      "nav-link " + (navSelected === navName ? "active" : "")
                    }
                  >
                    {" "}
                    {formaters.capitaliseEveryFirstLetter(navName)}
                  </a>
                </li>
              ))}
            </ul>
            Année en cours d'étude :
            <select
              value={year ? year.toString() : ""}
              onChange={(event) => onSetYear(parseInt(event.target.value))}
            >
              {yearChoices.map((year) => (
                <option key={year}>{year}</option>
              ))}
            </select>
          </div>
        </div>
      </nav>
    </>
  );
};

export default MainNavBar;
