import { financePercentageCent } from "vincent-utils";
import {VentilationWithPourcentage} from "compta-shared";

const applyPourcentageVentilations = (
  ventilations: VentilationWithPourcentage[],
  montantCent: number
) => {
  const montants = financePercentageCent(
    montantCent,
    ventilations.map(v => v.pourcentageCent)
  );
  return ventilations.map((ventilation, idx) => {
    return {
      compte: ventilation.compte,
      montantCent: montants[idx],
      pourcentageCent: ventilation.pourcentageCent
    };
  });
};

export default applyPourcentageVentilations;
