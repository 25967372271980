import MainFactureInputPanel from "../components/MainFactureInputPanel";
import { trpc } from "../../main/components/ProviderContainer.tsx";
import { useSearch } from "wouter";

const FacturesInputContainer = () => {
  const queryString = useSearch();
  const searchParams = new URLSearchParams(queryString);
  console.log("queryString", queryString);
  const selectedFactureId = searchParams.get("factureId");
  const { data: facture } = trpc.facture.getFactureById.useQuery(
    selectedFactureId as string,
    { enabled: !!selectedFactureId },
  );
  const onDone = () => {
    const state = history.state;
    if (state === "back") {
      setTimeout(() => {
        history.back();
      }, 1000);
    }
  };

  // const { data: facture } = useQuery(
  //   ["factures", selectedFactureId],
  //   () => {
  //     return fetchFacture(selectedFactureId as string);
  //   },
  //   { enabled: !!selectedFactureId }
  // );

  return (
    <MainFactureInputPanel selectedFacture={facture || null} onDone={onDone} />
  );
};
export default FacturesInputContainer;
