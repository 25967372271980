import { VentilationsState } from "../hooks/useVentilations";
import _ from "underts";

export const ventilationsValidator = (
  state: VentilationsState,
  areSimilarLinesSelected: boolean
) => {
  const errors: string[] = [];
  const withoutEmptyLines = state.ventilations.filter(
    ventil => ventil.compte !== "" || ventil.montantCent !== 0
  );
  const actualTotal = withoutEmptyLines.reduce((sum, ventilation) => {
    return sum + ventilation.montantCent;
  }, 0);
  if (actualTotal !== state.totalCent) {
    errors.push("Les Totaux ne correspondent pas");
  }
  const pourcentagesTotal = withoutEmptyLines.reduce((sum, ventilation) => {
    return sum + (ventilation.pourcentageCent || 0);
  }, 0);
  if (pourcentagesTotal !== 10000 && pourcentagesTotal !== 0) {
    errors.push("La somme des pourcentages n'est pas égale à 100");
  }
  if (withoutEmptyLines.map(v => v.compte).some(c => !c)) {
    errors.push("Certaines lignes n'ont pas de compte");
  }
  if (withoutEmptyLines.some(v => !v.montantCent)) {
    errors.push("Certaines lignes n'ont ni sortie ni entrée");
  }
  if (
    withoutEmptyLines.some(v => v.pourcentageCent) &&
    withoutEmptyLines.some(v => !v.pourcentageCent)
  ) {
    errors.push("Soit toutes les lignes ont un pourcentage soit aucune");
  }
  if (
    areSimilarLinesSelected &&
    withoutEmptyLines.length > 1 &&
    withoutEmptyLines.some(v => !v.pourcentageCent)
  ) {
    errors.push(
      "Pour appliquer à des enregistrements ressemblants, vous devez ne définir qu'un seul compte ou utiliser les pourcentages pour tous les comptes"
    );
  }
  //on a des similarLines sélectionnée et plusieurs lignes de comptes mais certaines n'ont pas de pourcentage
  if (
    areSimilarLinesSelected &&
    withoutEmptyLines.every(
      v => !v.pourcentageCent && withoutEmptyLines.length !== 1
    )
  ) {
    errors.push(
      "Pour appliquer à des enregistrements ressemblants, vous devez ne définir qu'un seul compte ou utiliser les pourcentages pour tous les comptes"
    );
  }
  return _.uniq(errors);
};
