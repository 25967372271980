import YearChooser from "../../factures/components/YearChooser.tsx";
import { formaters } from "vincent-utils";
import BanqueChooser from "./BanqueChooser.tsx";
import HonorairesList from "./HonorairesList.tsx";
import VentilationsContainer from "../containers/VentilationsContainer.tsx";
import React, { useEffect } from "react";
import _ from "underts";
import { trpc } from "../../main/components/ProviderContainer.tsx";
import { BanqueLine2, ManualComptaLine } from "compta-shared";
import { DateTime } from "luxon";
import {
  candidatesFlagger,
  montantCombinaisons2,
} from "../processing/montantCombinaisons.ts";
import { RapprochementPanelComps } from "./RapprochementMainComp.tsx";
import { useLocation } from "wouter";

type PanelType =
  | "rapprocheVirements"
  | "rapprocheCartes"
  | "rapprocheCheques"
  | "olderHonoraires";

interface RapprocheCompProps {
  panel: PanelType;
}

const getMontant = (banqueLine: BanqueLine2, mode: RapprochementPanelComps) => {
  switch (mode) {
    case "rapprocheVirements":
    case "rapprocheCheques":
      return banqueLine.montantCent;
    case "rapprocheCartes":
      return banqueLine.type === "remiseCartes"
        ? banqueLine.remiseCBBrut
        : null;
    default:
      throw new Error("mode is not defined");
  }
};
const RapprocheComp = (props: RapprocheCompProps) => {
  const [location, setLocation] = useLocation();
  const yearSelected = _.parseInt(location.split("/")[1]);
  const handleSelectYear = (year: number) => {
    setLocation("/" + year, { replace: true });
  };

  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [linesChecked, setLinesChecked] = React.useState<string[]>([]);

  const handleToggleCheck = (id: string) => {
    setLinesChecked((ids) =>
      ids.includes(id) ? ids.filter((i) => i !== id) : [...ids, id]
    );
  };
  const currentYear = new Date().getFullYear();
  const previousYears = _.range(5, -1, -1).map((i) => currentYear - i);

  const { data: virementsCréditeurs } =
    trpc.banque.getVirementsCréditeurs.useQuery(yearSelected as number, {
      enabled: props.panel === "rapprocheVirements" && yearSelected !== null,
    });

  const { data: remisesChèques } = trpc.banque.getChèquesCréditeurs.useQuery(
    yearSelected as number,
    {
      enabled: props.panel === "rapprocheCheques" && yearSelected !== null,
    }
  );

  const { data: remisesCartes } = trpc.banque.getCartesCréditrices.useQuery(
    yearSelected as number,
    {
      enabled: props.panel === "rapprocheCartes" && yearSelected !== null,
    }
  );

  const [selectedBanqueLineId, setSelectedBanqueLineId] = React.useState<
    string | null
  >(null);

  const handleSelectBanqueLineId = (id: string) => {
    setSelectedBanqueLineId(id);
    setCombinaisonNumberSelected(null);
    setLinesChecked([]);
  };

  const banqueLines =
    (props.panel === "rapprocheVirements"
      ? virementsCréditeurs
      : props.panel === "rapprocheCheques"
        ? remisesChèques
        : remisesCartes) || [];
  const selectedBanqueLine =
    banqueLines.find((b: BanqueLine2) => b._id === selectedBanqueLineId) ||
    null;
  const [selectedRecetteBookDate, setSelectedRecetteBookDate] =
    React.useState<Date>(new Date());

  const onPostponeRecetteBookDate = () => {
    setSelectedRecetteBookDate((date) =>
      DateTime.fromJSDate(date).plus({ months: 1 }).toJSDate()
    );
  };

  const onPreponeRecetteBookDate = () => {
    setSelectedRecetteBookDate((date) =>
      DateTime.fromJSDate(date).minus({ months: 1 }).toJSDate()
    );
  };
  const dt = DateTime.fromJSDate(selectedRecetteBookDate);
  const start =
    props.panel === "rapprocheVirements" || props.panel === "rapprocheCartes"
      ? dt.minus({ days: 10 }).toJSDate()
      : dt.minus({ months: 6 }).toJSDate();
  const end = dt.plus({ days: 10 }).toJSDate();

  useEffect(() => {
    selectedBanqueLine && setSelectedRecetteBookDate(selectedBanqueLine.date);
  }, [selectedBanqueLine && selectedBanqueLine.date.getTime()]);

  const codedLogosMode =
    props.panel === "rapprocheVirements"
      ? "V1"
      : props.panel === "rapprocheCheques"
        ? "B1"
        : "C1";

  const codedMode: ManualComptaLine["mode"] =
    props.panel === "rapprocheVirements"
      ? "Virement1"
      : props.panel === "rapprocheCheques"
        ? "Chèque1"
        : "Carte1";

  const { data: honoraires } =
    trpc.logLine.getHonorairesLinesFromWindow.useQuery(
      {
        start: start,
        end: end,
        mode: codedLogosMode,
      },
      {
        enabled: !!selectedBanqueLine,
      }
    );

  const { data: manualComptaLines } =
    trpc.manualComptaLine.getByDateAndMode.useQuery(
      {
        start: start,
        end: end,
        mode: codedMode,
        isRapproched: false,
      },
      {
        enabled: !!selectedBanqueLine,
      }
    );

  console.log("manualComptaLines", manualComptaLines);

  const linesToCompare = [
    ...((honoraires && honoraires.map((h) => ({ ...h, type: "LOG_TRESO" }))) ||
      []),
    ...((manualComptaLines &&
      manualComptaLines.map((c) => ({ ...c, type: "MANUAL_COMPTA_LINE" }))) ||
      []),
  ];
  const target = selectedBanqueLine
    ? getMontant(selectedBanqueLine, props.panel) || 0
    : 0;

  const chèquesCount = selectedBanqueLine
    ? "remiseChequeCount" in selectedBanqueLine
      ? selectedBanqueLine.remiseChequeCount
      : 0
    : 0;

  const combinaisons =
    chèquesCount + linesChecked.length < 10
      ? montantCombinaisons2(
          linesToCompare || [],
          target,
          linesChecked,
          chèquesCount || undefined
        )
      : [];

  //todo: ajouter un avertissement quand le nombre de combinaisons est trop élevé

  const [combinaisonNumberSelected, setCombinaisonNumberSelected] =
    React.useState<number | null>(null);

  const onSetCombinaisonNumberSelected = (n: number | null) => {
    setCombinaisonNumberSelected(n);
    if (typeof n === "number") {
      setLinesChecked(combinaisons[n].map((c) => c._id).filter((id) => !!id));
    }
  };

  const combinaisonSelected =
    combinaisonNumberSelected != null
      ? combinaisons[combinaisonNumberSelected]
      : null;
  const combinaisonsFlagged = candidatesFlagger(
    linesToCompare || [],
    combinaisons
  );
  console.log("combinaisonsFlagged", combinaisonsFlagged);
  const [showVentilations, setShowVentilations] = React.useState(false);
  const onShowVentilations = (banqueLineId: string) => {
    handleSelectBanqueLineId(banqueLineId);
    setShowVentilations(true);
  };
  const onCancelVentilations = () => {
    setShowVentilations(false);
  };

  console.log("combinaisonSelected", combinaisonSelected);
  const utils = trpc.useContext();
  const commitRapprochementMutation =
    trpc.rapprochement.commitMultipleSourceRapprochement.useMutation({
      onSuccess: () => {
        setIsSubmitting(false);
        setSelectedBanqueLineId(null);
        setCombinaisonNumberSelected(null);
        setLinesChecked([]);
        utils.banque.getChèquesCréditeurs.refetch();
        utils.banque.getVirementsCréditeurs.refetch();
        utils.banque.getCartesCréditrices.refetch();
        // utils.banque.invalidate();
        //todo: ne fonctionne pas, voir si pas un bug trpc
        utils.logLine.invalidate();
        utils.manualComptaLine.invalidate();
      },
      onError: () => {
        alert("Erreur lors de la validation du rapprochement");
      },
    });

  const actualLinesChecked = linesToCompare.filter((l) =>
    linesChecked.includes(l._id)
  );
  const winningCombinaison =
    combinaisonSelected ||
    (actualLinesChecked.reduce((acc, l) => acc + l.montantCent, 0) === target
      ? actualLinesChecked
      : null) ||
    [];
  console.log("winningCombinaison", winningCombinaison);
  const commitRapprochement = async () => {
    if (
      winningCombinaison.length === 0 ||
      !selectedBanqueLineId ||
      isSubmitting
    )
      return;
    setIsSubmitting(true);
    commitRapprochementMutation.mutate({
      banqueLineId: selectedBanqueLineId,
      logLinesIds: winningCombinaison
        .filter((c) => "type" in c && c.type === "LOG_TRESO")
        .map((c) => c._id),
      manualComptaLinesIds: winningCombinaison
        .filter((c) => "type" in c && c.type === "MANUAL_COMPTA_LINE")
        .map((c) => c._id),
    });
  };
  return (
    <div>
      <div>
        <div className="mt-2">
          <div className="row mt-2">
            <div className="col d-flex justify-content-between">
              <YearChooser
                yearChoices={previousYears}
                onYearSelected={handleSelectYear}
                yearSelected={yearSelected}
              />
              {winningCombinaison.length > 0 && (
                <button
                  type="button"
                  className={"btn btn-primary"}
                  onClick={commitRapprochement}
                >
                  Enregistrer rapprochement
                </button>
              )}
            </div>
            <div className={"col"}>
              <div className="btn-group">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={onPreponeRecetteBookDate}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-dash-lg"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M2 8a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11A.5.5 0 0 1 2 8Z"
                    />
                  </svg>
                </button>
                <button type="button" className="btn btn-light">
                  du {formaters.formatDateAsDDN(start)} au{" "}
                  {formaters.formatDateAsDDN(end)}
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={onPostponeRecetteBookDate}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-plus-lg"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col">
              <div className="card">
                <div className="card-header">
                  Lignes du compte bancaire non rapprochées
                </div>
                <BanqueChooser
                  banqueLines={banqueLines}
                  selectBanqueLine={handleSelectBanqueLineId}
                  banqueLineSelected={selectedBanqueLine}
                  showVentilations={onShowVentilations}
                />
              </div>
            </div>
            <div className="col">
              <div className="card">
                <div className="card-header">
                  Lignes non rapprochées dans le livre de recettes : du{" "}
                  {formaters.formatDateAsDDN(start)} au{" "}
                  {formaters.formatDateAsDDN(end)}
                </div>
                <HonorairesList
                  honoraires={combinaisonsFlagged || []}
                  combinaisonSelected={combinaisonNumberSelected}
                  onSelectCombinaison={onSetCombinaisonNumberSelected}
                  isReady={true} //todo
                  hasData={true} //todo
                  linesChecked={linesChecked}
                  onToggleCheck={handleToggleCheck}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {showVentilations && selectedBanqueLineId ? (
        <VentilationsContainer
          banqueLineId={selectedBanqueLineId}
          onCancelVentilations={onCancelVentilations}
          actionId={null}
          similarLinesIds={[]}
        />
      ) : null}
    </div>
  );
};

export default RapprocheComp;
