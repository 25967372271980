import { useEffect, useState } from "react";
import calcAmortissement, {
  calcTauxDégressif,
  walkDegressiveAmortissement,
  walkLinearAmortissement
} from "./calcAmortissement";
import {produce} from "immer";
import {AmortissementLine, Immobilisation} from "compta-shared";

export interface ImmobilisationState {
  _id: string | null;
  owner: string | null;
  name: string;
  principalCent: number | null;
  years: number | null;
  startDate: Date | null;
  mode: "linéaire" | "dégressif" | null;
  amortissementLines: AmortissementLine[];
}

const getDefaultState = (): ImmobilisationState => ({
  _id: null,
  owner: null,
  name: "",
  principalCent: null,
  years: null,
  mode: null,
  startDate: null,
  amortissementLines: []
});

const useImmobilisation = (existingImmobilisation: Immobilisation | null) => {
  const [immobilisation, setImmobilisation] = useState<ImmobilisationState>(
    existingImmobilisation || getDefaultState()
  );
  useEffect(() => {
    if (existingImmobilisation) {
      setImmobilisation(existingImmobilisation);
    }
  }, [JSON.stringify(existingImmobilisation)]);
  console.log("immobilisation", immobilisation);
  const [isDirty, setIsDirty] = useState(false);
  const [lineDirtyIndex, setLineDirtyIndex] = useState<number | null>(null);
  const onRecalculate = () => {
    setImmobilisation(immoState => ({
      ...immoState,
      amortissementLines: calcAmortissement(immoState)
    }));
    setIsDirty(false);
  };
  const onSetName = (name: string) => {
    setImmobilisation(immobilisation => ({ ...immobilisation, name }));
  };
  const onSetPrincipalCent = (principalCent: number | null) => {
    setImmobilisation(immobilisation => ({ ...immobilisation, principalCent }));
    setIsDirty(true);
  };
  const onSetYears = (years: number | null) => {
    setImmobilisation(immobilisation => ({ ...immobilisation, years }));
    setIsDirty(true);
  };
  const onSetMode = (mode: "linéaire" | "dégressif" | null) => {
    setImmobilisation(immobilisation => ({ ...immobilisation, mode }));
    setIsDirty(true);
  };
  const onSetStartDate = (startDate: Date | null) => {
    setImmobilisation(immobilisation => ({ ...immobilisation, startDate }));
    setIsDirty(true);
  };
  const onModifyAmortissementFromLine = (valueCent: number, index: number) => {
    setImmobilisation(immobilisation => {
      return produce(immobilisation, draft => {
        const previousValueCent =
          draft.amortissementLines[index].amortissementCent;
        draft.amortissementLines[index].amortissementCent = valueCent;
        draft.amortissementLines[index].amortissementCumuléCent =
          draft.amortissementLines[index].amortissementPrécédentCent +
          valueCent;
        draft.amortissementLines[index].valeurRésiduelleCent =
          draft.amortissementLines[index].valeurRésiduelleCent +
          (previousValueCent - valueCent);
      });
    });
    setLineDirtyIndex(index + 1);
  };

  const onRecalcFromLine = (index: number) => {
    setImmobilisation(immobilisation => {
      if (index === 0) {
        return {
          ...immobilisation,
          amortissementLines: calcAmortissement(immobilisation)
        };
      }
      const previousLine = immobilisation.amortissementLines[index - 1];
      if (immobilisation.mode === "linéaire") {
        return {
          ...immobilisation,
          amortissementLines: walkLinearAmortissement(
            immobilisation.amortissementLines.slice(0, index),
            Math.round(
              (immobilisation.principalCent as number) /
                (immobilisation.years as number)
            ),
            previousLine.valeurRésiduelleCent
          )
        };
      }
      if (immobilisation.mode === "dégressif") {
        const tauxDégressif = calcTauxDégressif(immobilisation.years as number);
        return {
          ...immobilisation,
          amortissementLines: walkDegressiveAmortissement(
            immobilisation.amortissementLines.slice(0, index),
            immobilisation.years as number,
            index,
            previousLine.valeurRésiduelleCent,
            tauxDégressif
          )
        };
      }
      throw new Error("should not happen (onRecalcFromLine)");
    });
    setLineDirtyIndex(null);
  };

  const showRecalculateButton =
    isDirty &&
    immobilisation.principalCent !== null &&
    immobilisation.years !== null &&
    immobilisation.mode !== null &&
    immobilisation.startDate !== null &&
    immobilisation.name !== "";
  return {
    immobilisation,
    onSetPrincipalCent,
    onSetMode,
    onSetName,
    onSetYears,
    onSetStartDate,
    onRecalculate,
    onModifyAmortissementFromLine,
    onRecalcFromLine,
    showRecalculateButton,
    isDirty,
    lineDirtyIndex
  };
};

export default useImmobilisation;
