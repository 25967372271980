import HonorairesComp from "./HonorairesComp";
import NotReadyHonoraireComp from "./NotReadyHonoraireComp";
import EmptyHonoraireComp from "./EmptyHonoraireComp";

interface HonorairesListProps {
  honoraires: {
    libellé: string;
    date: Date;
    montantCent: number;
    proposed: number[]; //numéros des combinaisons de rapprochement proposées
    "n° de bordereau de banque"?: string;
    _id: string;
  }[];
  combinaisonSelected: number | null;
  onSelectCombinaison: (number: number) => void;
  isReady: boolean;
  hasData: boolean;
  linesChecked: string[];
  onToggleCheck: (id: string) => void;
}

const HonorairesList = ({
  honoraires,
  combinaisonSelected,
  onSelectCombinaison,
  isReady,
  hasData,
    linesChecked,
    onToggleCheck
}: HonorairesListProps) => {
  const comps =
    honoraires.length === 0 ? (
      isReady ? (
        hasData ? (
          <NotReadyHonoraireComp />
        ) : (
          <EmptyHonoraireComp />
        )
      ) : (
        <NotReadyHonoraireComp />
      )
    ) : (
      honoraires.map((honoraire, idx) => (
        <HonorairesComp
          {...honoraire}
          combinaisonSelected={combinaisonSelected}
          onSelectCombinaison={onSelectCombinaison}
          key={"honorairesLogLine" + idx}
          checked={linesChecked.includes(honoraire._id)}
            onToggleCheck={() => onToggleCheck(honoraire._id)}

        />
      ))
    );
  return (
    <div>
      <ul className="list-group">{comps}</ul>
    </div>
  );
};

export default HonorairesList;
