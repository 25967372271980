import DateDisplayerComp from "../../utils/components/DateDisplayerComp.tsx";
import RapprochéBadge from "./RapprochéBadge.tsx";

interface FactureRGlementLineProps {
  date: Date | undefined;
  montant: string;
  banqueId: string;
}

const FactureRèglementLine = (props: FactureRGlementLineProps) => {
  const { date, montant, banqueId } = props;
  return (
    <div>
      {date ? <DateDisplayerComp date={date} /> : ""} {montant}{" "}
      <RapprochéBadge isRapproché={!!banqueId} />
    </div>
  );
};

export default FactureRèglementLine;
