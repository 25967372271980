import DepenseLine from "./DepenseLine";
import _ from "underts";
import type { BanqueLine2} from "compta-shared";

interface DepensesListProps {
  banqueLines: BanqueLine2[];
  onVentiler: (id: string) => void;
}

const DepensesList = ({ banqueLines, onVentiler }: DepensesListProps) => {
  const banqueComps = _.sortBy(banqueLines, "date").map((banqueLine, idx) => (
    <DepenseLine
      selected={false}
      {...banqueLine}
      onVentiler={onVentiler}
      key={"depenseLine" + idx}
    />
  ));
  return <ul className="list-group">{banqueComps}</ul>;
};

export default DepensesList;
