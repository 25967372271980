import VentilationsLines, { VentilationsLinesProps } from "./VentilationsLines";
import { ModalHeader, ModalFooter } from "react-bootstrap";
import SimilarLinesContainer from "../containers/SimilarLinesContainer";
import { formaters, integerCentToFrenchFinanString } from "vincent-utils";
import { BanqueLine2 } from "compta-shared";

interface VentilationMainPanelProps {
  errors: string[];
  ventilations: VentilationsLinesProps["ventilations"];
  montantCent: number;
  totalActuelVentilationsCent: number;
  line?: BanqueLine2;
  similarLinesIds: string[];
  onSetSimilarLinesIds: (ids: string[]) => void;
  onValueChange: VentilationsLinesProps["onValueChange"];
  onDeleteVentilation: VentilationsLinesProps["onDeleteVentilation"];
  onCancelVentilations: () => void;
  acceptVentilations: () => void;
  deleteAllEnabled: boolean;
  onDeleteAllVentilations: () => void;
}

const VentilationMainPanel = (props: VentilationMainPanelProps) => {
  const { ventilations } = props;
  const { montantCent, totalActuelVentilationsCent, errors } = props;
  const { onValueChange, onDeleteVentilation } = props;
  const { onCancelVentilations, acceptVentilations } = props;
  const { line } = props;

  const showAcceptButton =
    (errors && errors.length === 0) || ventilations.length === 0;
  return (
    <div>
      <ModalHeader>Ventilations</ModalHeader>
      <div className="card">
        <div className="card-header">
          <div>
            <>
              {(line && formaters.formatDateAsDDN(line.date)) || ""}{" "}
              {line?.libelle} Total cible :{" "}
              {integerCentToFrenchFinanString(montantCent)} - Total actuel :{" "}
              {integerCentToFrenchFinanString(totalActuelVentilationsCent)}
            </>
            {errors &&
              errors.map((msg, idx) => (
                <span
                  className="badge bg-danger ml-1 mx-1"
                  style={{ fontSize: "1.1em" }}
                  key={"ventilationMainPanelErrors" + idx}
                >
                  {msg}
                </span>
              ))}
          </div>
        </div>
        <div className="card-body">
          <VentilationsLines
            ventilations={ventilations}
            onValueChange={onValueChange}
            onDeleteVentilation={onDeleteVentilation}
          />
        </div>
      </div>
      {line ? (
        <SimilarLinesContainer
          lineToCompare={line}
          similarLinesIds={props.similarLinesIds}
          onSetSimilarLinesIds={props.onSetSimilarLinesIds}
        />
      ) : null}
      <ModalFooter>
        <button
          type="button"
          className="btn btn-secondary"
          onClick={onCancelVentilations}
        >
          {" "}
          Annuler
        </button>
        {showAcceptButton ? (
          <button
            type="button"
            className="btn btn-primary"
            onClick={acceptVentilations}
          >
            {" "}
            Valider
          </button>
        ) : (
          ""
        )}
        {props.deleteAllEnabled ? (
          <button
            type="button"
            className="btn btn-danger"
            onClick={props.onDeleteAllVentilations}
          >
            Supprimer ventilations
          </button>
        ) : null}
      </ModalFooter>
    </div>
  );
};

export default VentilationMainPanel;
