import { integerCentToFloatString } from "vincent-utils";
import { trpc } from "../main/components/ProviderContainer.tsx";
import { Redirect, Route, Switch } from "wouter";

const integerCentToRoundedInteger = (integerCent: number) => {
  const floatString = integerCentToFloatString(integerCent);
  if (!floatString) return 0;
  const float = parseFloat(floatString);
  return Math.round(float);
};

const valDisplay = (val: number, negative?: true) =>
  (negative ? -1 : 1) * integerCentToRoundedInteger(val);

interface A2035Props {
  yearSelected: number | null;
}

const A2035 = (props: A2035Props) => {
  // const { data: docObject } = useQuery(
  //   ["2035", yearSelected],
  //   () => get2035A(yearSelected as number),
  //   { enabled: !!yearSelected }
  // );
  const { data: docObject } = trpc.grandLivre.get2035.useQuery(
    props.yearSelected as number,
    { enabled: !!props.yearSelected },
  );

  // const line4 = docObject
  //   ? (docObject["1"] || 0) - (docObject["2"] || 0) - (docObject["3"] || 0)
  //   : 0;
  // const line7 = docObject
  //   ? line4 + (docObject["5"] || 0) + (docObject["6"] || 0)
  //   : 0;
  // const total1722 = docObject
  //   ? (docObject["17"] || 0) +
  //     (docObject["18"] || 0) +
  //     (docObject["19"] || 0) +
  //     (docObject["20"] || 0) +
  //     (docObject["21"] || 0) +
  //     (docObject["22"] || 0)
  //   : 0;
  if (!docObject) return <div></div>;
  return (
    <Switch>
      <Route path="/:year">
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-1 border">1</div>
              <div className="col-8 border">
                Recettes encaissées y compris les remboursements de frais
              </div>
              <div className="col-1 border border-dark px-3">
                {"1" in docObject
                  ? integerCentToRoundedInteger(docObject["1"])
                  : 0}
              </div>
            </div>
            <div className="row">
              <div className="col-1 border border-dark">
                <div>2</div>
                <div>3</div>
              </div>
              <div className="col-4 align-self-center border-bottom">
                <div>A déduire</div>
              </div>
              <div className="col-4">
                <div>Débours payés pour le compte des clients</div>
                <div>Honoraires rétrocédés</div>
              </div>
              <div className="col-1 px-0">
                <div className="border border-dark m-0 px-3">
                  {"2" in docObject
                    ? integerCentToRoundedInteger(docObject["2"])
                    : 0}
                </div>
                <div className="border border-dark m-0 px-3">
                  {"3" in docObject
                    ? integerCentToRoundedInteger(docObject["3"])
                    : 0}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-1 border">4</div>
              <div className="col-8 border">Montant net des recettes</div>
              <div className="col-1 border border-dark px-3">
                {integerCentToRoundedInteger(docObject["4"])}
              </div>
            </div>
            <div className="row">
              <div className="col-1 border border-dark">5</div>
              <div className="col-8 border">Produit financiers</div>
              <div className="col-1 border border-dark px-3">
                {valDisplay(docObject[5])}
              </div>
            </div>
            <div className="row">
              <div className="col-1 border border-dark">6</div>
              <div className="col-8 border">Gain divers</div>
              <div className="col-1 border border-dark px-3">
                {valDisplay(docObject[6])}
              </div>
            </div>
            <div className="row">
              <div className="col-1 border border-dark">7</div>
              <div className="col-8 border">TOTAL (lignes 4 à 6)</div>
              <div className="col-1 border border-dark px-3">
                {valDisplay(docObject[7])}
              </div>
            </div>
            <div className="row">3 Dépenses professionnelles</div>
            <div className="row">
              <div className="col-1 border border-dark">8</div>
              <div className="col-8 border">Achats</div>
              <div className="col-1 border border-dark px-3">
                {valDisplay(docObject[8])}
              </div>
            </div>
            <div className="row">
              <div className="col-1 border border-dark">
                <div>9</div>
                <div>10</div>
              </div>
              <div className="col-2 border">Frais de personnel</div>
              <div className="col-6 align-self-center border-bottom">
                <div>Salaires nets et avantages en nature</div>
                <div>
                  Charges sociales sur salaires (parts patronale et ouvrière)
                </div>
              </div>
              <div className="col-1 border border-dark px-3">
                <div>{valDisplay(docObject[9])}</div>
                <div>{valDisplay(docObject[10])}</div>
              </div>
            </div>
            <div className="row">
              <div className="col-1 border border-dark">
                <div>11</div>
                <div>12</div>
                <div>13</div>
                <div>14</div>
              </div>
              <div className="col-2 border">Impôts et taxes</div>
              <div className="col-6 align-self-center border-bottom">
                <div>Taxe sur la valeur ajoutée</div>
                <div>Contribution économique territoriale</div>
                <div>Autres impôts</div>
                <div>Contribution sociale généralisée déductible</div>
              </div>
              <div className="col-1 border border-dark px-3">
                <div>{valDisplay(docObject[11])}</div>
                <div>{valDisplay(docObject[12])}</div>
                <div>{valDisplay(docObject[13])}</div>
                <div>{valDisplay(docObject[14])}</div>
              </div>
            </div>
            <div className="row">
              <div className="col-1 border border-dark">
                <div>15</div>
              </div>
              <div className="col-8 border">Loyers et charges locatives</div>
              <div className="col-1 border border-dark px-3">
                {valDisplay(docObject[15])}
              </div>
            </div>
            <div className="row">
              <div className="col-1 border border-dark">
                <div>16</div>
              </div>
              <div className="col-6 border">
                Location de matériel et de mobilier - dont redevances de
                collaboration
              </div>
              <div className="col-1 border border-dark px-3">0 TODO</div>
              <div className="col-1 border border-dark px-3"></div>
              <div className="col-1 border border-dark px-3">
                {valDisplay(docObject[16])}
              </div>
            </div>
            <div className="row">
              <div className="col-1 border border-dark">
                <div>17</div>
                <div>18</div>
                <div>19</div>
                <div>20</div>
                <div>21</div>
                <div>22</div>
              </div>
              <div className="col-6 border">
                <div>Entretiens et réparations</div>
                <div>Personnel intérimaire</div>
                <div>Petit outillage</div>
                <div>Chauffage, eau, gaz, électricité</div>
                <div>Honoraires ne constituant pas des rétrocessions</div>
                <div>Primes d'assurances</div>
              </div>
              <div className="col-1 border border-dark px-3">
                <div>{valDisplay(docObject[17])}</div>
                <div>{valDisplay(docObject[18])}</div>
                <div>{valDisplay(docObject[19])}</div>
                <div>{valDisplay(docObject[20])}</div>
                <div>{valDisplay(docObject[21])}</div>
                <div>{valDisplay(docObject[22])}</div>
              </div>
              <div className="col-1 border border-dark px-3">
                TOTAL : travaux, fournitures et services extérieurs
              </div>
              <div className="col-1 border border-dark px-3">
                {integerCentToRoundedInteger(docObject["BH"])}
              </div>
            </div>
            <div className="row">
              <div className="col-1 border border-dark">
                <div>23</div>
                <div>24</div>
              </div>
              <div className="col-6 border">
                <div>Frais de véhicules</div>
                <div>(cochez la case si évaluation forfaitaire)</div>
                <div>Autres frais de déplacement (voyages...)</div>
              </div>
              <div className="col-1 border border-dark px-3">
                <div>{valDisplay(docObject[23])}</div>
                <div>
                  {docObject.forfaitKilométrique ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-check-lg"
                      viewBox="0 0 16 16"
                    >
                      <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
                    </svg>
                  ) : null}
                </div>
                <div>{valDisplay(docObject[24])}</div>
              </div>
              <div className="col-1 border border-dark px-3">
                TOTAL : transports et déplacements
              </div>
              <div className="col-1 border border-dark px-3">
                {integerCentToRoundedInteger(docObject["BJ"])}
              </div>
            </div>
            <div className="row">
              <div className="col-1 border border-dark">
                <div>25</div>
              </div>
              <div className="col-2 border">Charges sociales personnelles</div>
              <div className="col-4 align-self-center border-bottom">
                <div>dont obligatoires</div>
                <div>dont facultative aux nouveaux plan d'épargne retraire</div>
                <div>dont facultatives Madelin</div>
              </div>
              <div className="col-1 border border-dark px-3">
                <div>{integerCentToRoundedInteger(docObject["25A"])}</div>
                <div>TODO</div>
                <div>{integerCentToRoundedInteger(docObject["25B"])}</div>
              </div>
              <div className="col-1 border border-dark px-3"></div>
              <div className="col-1 border border-dark px-3">
                {integerCentToRoundedInteger(docObject["BK"])}
              </div>
            </div>
            <div className="row">
              <div className="col-1 border border-dark">
                <div>26</div>
                <div>27</div>
                <div>28</div>
                <div>29</div>
                <div>30</div>
              </div>
              <div className="col-6 border">
                <div>Frais de réception, de représentation et de congrès</div>
                <div>
                  Fournitures de bureau, frais de documentation, de
                  correspondance et de téléphone
                </div>
                <div>Frais d'actes et de contentieux</div>
                <div>Cotisations syndicales et professionnelles</div>
                <div>Autres frais divers de gestion</div>
              </div>
              <div className="col-1 border border-dark px-3">
                <div>{valDisplay(docObject[26])}</div>
                <div>{valDisplay(docObject[27])}</div>
                <div>{valDisplay(docObject[28])}</div>
                <div>{valDisplay(docObject[29])}</div>
                <div>{valDisplay(docObject[30])}</div>
              </div>
              <div className="col-1 border border-dark px-3">
                TOTAL : frais divers de gestion
              </div>
              <div className="col-1 border border-dark px-3">
                {valDisplay(docObject["BM"])}
              </div>
            </div>
            <div className="row">
              <div className="col-1 border border-dark">
                <div>31</div>
              </div>
              <div className="col-8 border">Frais financiers</div>
              <div className="col-1 border border-dark px-3">
                {valDisplay(docObject[31])}
              </div>
            </div>
            <div className="row">
              <div className="col-1 border border-dark">
                <div>32</div>
              </div>
              <div className="col-8 border">Pertes diverses</div>
              <div className="col-1 border border-dark px-3">
                {valDisplay(docObject[32])}
              </div>
            </div>
            <div className="row">
              <div className="col-1 border border-dark">
                <div>33</div>
              </div>
              <div className="col-8 border">TOTAL (lignes 8 à 32)</div>
              <div className="col-1 border border-dark px-3">
                {valDisplay(docObject[33])}
              </div>
            </div>
          </div>
        </div>
      </Route>
      <Redirect to={`/${props.yearSelected}`} />
    </Switch>
  );
};

export default A2035;
